import { bulkEditForm } from '@/objects/bulkEditDiaglogFields'

const bulkEditHelper = {
  bulkEditForm,
  getForm() {
    return JSON.parse(JSON.stringify(this.bulkEditForm.form))
  },
  verify(updateParams, invoices, invoiceValidator) {
    const checkWillUpdate = (value) => {
      if (typeof value === 'string') {
        return value
      } else {
        return value && value.id
      }
    }
    const unupdatablePaymentSet = new Set(
      this.bulkEditForm.unupdatableFieldPaymentApproved
    )
    const unupdatableJournalSet = new Set(
      this.bulkEditForm.unupdatableFieldJournalApproved
    )

    let [updateJournal, updatePayment] = [false, false]
    for (const [field, value] of Object.entries(updateParams)) {
      const shouldCheckJournal =
        !updateJournal && unupdatableJournalSet.has(field)
      if (shouldCheckJournal) {
        if (checkWillUpdate(value)) {
          updateJournal = true
        }
      } else if (!updatePayment && unupdatablePaymentSet.has(field)) {
        //shouldCheckPayment
        if (value !== '' && value !== undefined) {
          updatePayment = true
        }
      }
    }
    let updatableInvoices = [...invoices]
    if (updateJournal) {
      updatableInvoices = updatableInvoices.filter(
        (invoice) => invoiceValidator.isJournalUpdatable(invoice)
      )
    }
    if (updatePayment) {
      updatableInvoices = updatableInvoices.filter(
        (invoice) => !invoice.date_pay_approve
      )
    }

    const updatableInvoiceIdSet = new Set(
      updatableInvoices.map((invoice) => invoice.id)
    )
    const unupdatableInvoices = invoices.filter(
      (invoice) => !updatableInvoiceIdSet.has(invoice.id)
    )
    const noFieldChanged = !(
      updateJournal ||
      updatePayment ||
      updateParams['note_1']
    )

    return {
      updatableInvoices,
      unupdatableInvoices,
      updateJournal,
      updatePayment,
      noFieldChanged,
    }
  },
}

export default Object.freeze(bulkEditHelper)
