<template>
  <div>
    <base-dialog
      ref="dialogAddFilterPattern"
      title="フィルタパターンの登録"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.add')"
      @submit="addPattern"
    >
      <template v-slot:card-text>
        <v-form ref="formAddFilterPattern" @submit.prevent>
          現在のフィルタ設定をパターンとして登録します。<br />
          パターン名を入力してください。
          <v-text-field
            v-model="addFilterPatternItem.name"
            :rules="patternNameRule"
            label="パターン名"
            required
            class="mt-5"
            dense
            outlined
            filled
          />
        </v-form>
      </template>
    </base-dialog>
    <base-dialog
      ref="dialogEditFilterPattern"
      title="フィルタパターン編集"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="'保存'"
      @submit="savePattern"
    >
      <template v-slot:card-text>
        <v-form ref="formEditFilterPattern" @submit.prevent>
          <v-radio-group v-model="editFilterPatternItem.display" row>
            <v-radio color="blue" label="表示" :value="true" />
            <v-radio color="blue" label="非表示" :value="false" />
          </v-radio-group>
          <v-text-field
            v-model="editFilterPatternItem.name"
            :disabled="!editFilterPatternItem.user_organization"
            label="パターン名"
            dense
            outlined
            filled
            :rules="patternNameRule"
          />
        </v-form>
      </template>
    </base-dialog>

    <base-dialog
      ref="dialogDeleteFilterPatternConfirm"
      title="フィルタパターン削除"
      message="このフィルタパターンを削除しますか？"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.delete')"
      @submit="deletePattern"
    />

    <v-dialog v-model="dialog" max-width="900">
      <v-card class="sweeep-dialog">
        <v-card-title v-text="'フィルタパターン設定'" />
        <v-card-text>
          <v-btn
            small
            depressed
            class="mb-3"
            @click="showAddFilterDialog"
          >
            <s-icon
              icon="feather-plus"
              color="gray-700"
              size="xl"
              class="mr-2"
            />
            現在の条件をフィルタパターンとして登録
          </v-btn>
          <v-data-table
            :headers="editDisplayHeaders"
            :items="filterPatterns"
            :footer-props="footerProps"
            height="420"
          >
            <template v-slot:item.apply="{ item }">
              {{ getAppliedFilterText(item) }}
            </template>

            <template v-slot:item.type="{ item }">
              <v-chip
                x-small
                label
                outlined
                :color="item.user_organization ? 'primary' : 'green'"
                v-text="item.user_organization ? 'カスタム' : 'デフォルト'"
              />
            </template>

            <template v-slot:item.display="{ item }">
              <v-chip
                x-small
                label
                outlined
                :color="item.display ? 'primary' : 'red'"
                v-text="item.display ? '表示' : '非表示'"
              />
            </template>

            <template v-slot:item.action="{ item }">
              <div class="d-flex justify-center">
                <v-btn
                  small
                  rounded
                  color="primary"
                  @click="openEditDialog(item)"
                >
                  {{ $t('message.table_edit_btn') }}
                </v-btn>
                <v-btn
                  :disabled="!item.user_organization"
                  class="ml-2"
                  small
                  rounded
                  color=""
                  @click="openDeleteConfirmDialog(item)"
                >
                  {{ $t('message.table_delete_btn') }}
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="close">
            {{$t('message.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    userList: {
      type: Array,
      required: true,
    },
    searchForm: {
      type: Object,
      required: true,
    },
    searchFormText: {
      type: Object,
      required: true,
    },
    filterPatterns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      editDisplayHeaders: [
        { text: 'フィルタパターン名', value: 'name', width: 200 },
        { text: '適用される条件', value: 'apply', sortable: false, width: 340 },
        {
          text: '作成区分',
          value: 'type',
          sortable: false,
          align: 'center',
          width: 90,
        },
        {
          text: '表示',
          value: 'display',
          sortable: false,
          align: 'center',
          width: 50,
        },
        { text: '', value: 'action', sortable: false },
      ],
      footerProps: {
        'items-per-page-text': '表示件数',
        'items-per-page-options': [10],
      },
      editFilterPatternItem: {},
      addFilterPatternItem: {
        name: '',
        filterPattern: null,
      },
      patternNameRule: [(v) => !!v || this.$t('message.form_input_required')],
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    getAppliedFilterText(item) {
      try {
        let text = ''
        let temp = ''
        if (item.days_from_today_reading_date != null) {
          temp = this.getDayText(
            'reading_date_from',
            item.days_from_today_reading_date
          )
          text += temp
        }
        if (item.days_from_today_issue_date != null) {
          temp = this.getDayText('date', item.days_from_today_issue_date)
          text += temp
        }
        if (item.days_from_today_pay_date != null) {
          temp = this.getDayText('pay_date_from', item.days_from_today_pay_date)
          text += temp
        }
        if (item.filter_pattern) {
          let obj = item.filter_pattern
          temp = this.appliedFilterText(obj)
          temp = temp.replace(/<br>/g, ' , ')
          temp = temp.replace(/ , $/g, '')
          text += temp
        }
        return text
      } catch (e) {
        console.log(e)
      }
    },
    getDayText(key, days) {
      let dayText
      let label = this.searchFormText[key].label
      if (days == 0) {
        dayText = '今日'
      } else if (days < 0) {
        dayText = String(Math.abs(days)) + '日前まで'
      } else {
        dayText = String(Math.abs(days)) + '日後まで'
      }
      return label + ' = ' + dayText
    },
    appliedFilterText(filter) {
      try {
        if (this.userList.length <= 0) {
          return
        }
        let ignoreKeys = [
          'headerDate',
          'headerDateTo',
          'filterDateType',
          'date',
          'date_to',
          'reading_date_from',
          'reading_date_to',
          'pay_date_from',
          'pay_date_to',
          'id_1',
          'id_2',
          'amount_1',
          'amount_2',
        ]
        let user_keys = [
          'upload_user',
          'update_user',
          'approve_user',
          'export_user',
          'pay_export_user',
          'pay_approve_user',
          'paid_user',
        ]
        let journal_keys = [
          'department',
          'debit_account_title',
          'debit_sub_account',
          'debit_department',
          'debit_vendor',
          'debit_project',
          'debit_segment',
          'debit_item',
          'debit_tag',
          'debit_walletable',
          'debit_tax_class',
          'credit_account_title',
          'credit_sub_account',
          'credit_department',
          'credit_vendor',
          'credit_project',
          'credit_segment',
          'credit_item',
          'credit_tag',
          'credit_walletable',
          'credit_tax_class',
        ]
        let result = ''
        const searchFormText = this.searchFormText

        if (filter['id_1'] || filter['id_2']) {
          result +=
            searchFormText['id_1'].label +
            ' = ' +
            filter['id_1'] +
            ' ~ ' +
            filter['id_2'] +
            '<br>'
        }
        if (filter['amount_1'] || filter['amount_2']) {
          result +=
            searchFormText['amount_1'].label +
            ' = ' +
            filter['amount_1'] +
            ' ~ ' +
            filter['amount_2'] +
            '<br>'
        }
        if (filter.deletedStatusChoice == 1) {
          ignoreKeys.push('deletedStatusChoice')
        }
        if (
          filter.settingsFilterBlank &&
          filter.settingsFilterBlank.issueDate
        ) {
          result += '伝票日付 = 未入力を検索<br>'
        }

        for (let key of Object.keys(filter)) {
          if (
            ignoreKeys.includes(key) === false &&
            !(
              filter[key] === '' ||
              filter[key] === null ||
              filter[key] === undefined
            ) &&
            !(searchFormText[key] === null || searchFormText[key] === undefined)
          ) {
            let textObj = searchFormText[key]
            let selectedText = ''
            let index = null
            if (textObj.label) {
              if (journal_keys.includes(key)) {
                selectedText = filter[key].name
              } else if (user_keys.includes(key)) {
                //ユーザ名取得
                if (filter[key] == 0) {
                  selectedText = '自分'
                } else {
                  index = this.userList.findIndex((x) => x.id == filter[key])
                  if (index !== -1 && this.userList[index].user) {
                    selectedText = this.userList[index].user.full_name
                  }
                }
              } else {
                if (textObj.items) {
                  //選択した値がitemsを持っていた場合、名前を取得する
                  index = textObj.items.findIndex((x) => x.id == filter[key])
                  if (index >= 0 && textObj.items[index].name) {
                    selectedText = textObj.items[index].name
                  } else if (
                    index >= 0 &&
                    textObj.items[index].user.full_name
                  ) {
                    selectedText = textObj.items[index].user.full_name
                  }
                } else {
                  //いずれにも該当しない場合は選択された値をそのまま取得
                  selectedText = filter[key]
                }
              }
              if (!(!selectedText || selectedText === undefined)) {
                result += textObj.label + ' = ' + selectedText + '<br>'
              }
            }
          }
        }
        if (result) {
          return result
        } else {
          return ''
        }
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    showAddFilterDialog() {
      this.addFilterPatternItem.filterPattern = this.searchForm
      this.$refs.dialogAddFilterPattern.open()
      this.$nextTick(() => {
        this.$refs.formAddFilterPattern.resetValidation()
      })
    },
    openEditDialog(item) {
      this.editFilterPatternItem = { ...item }
      this.$refs.dialogEditFilterPattern.open()
    },
    addPattern() {
      if (this.validate('add')) {
        this.$emit('add', this.addFilterPatternItem)
        this.formFilterPattern = {
          name: '',
          filterPattern: null,
        }
        this.$refs.dialogAddFilterPattern.close()
      }
    },
    savePattern() {
      if (this.validate('save')) {
        this.$emit('save', this.editFilterPatternItem)
        this.$refs.dialogEditFilterPattern.close()
      }
    },
    openDeleteConfirmDialog(item) {
      this.editFilterPatternItem = { ...item }
      this.$refs.dialogDeleteFilterPatternConfirm.open()
    },
    deletePattern() {
      this.$emit('delete', this.editFilterPatternItem.id)
      this.$refs.dialogDeleteFilterPatternConfirm.close()
    },
    validate(status) {
      try {
        if (status === 'add') {
          return this.$refs.formAddFilterPattern.validate()
        } else {
          return this.$refs.formEditFilterPattern.validate()
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
