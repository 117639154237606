<template>
  <div>
    <dialog-settings-filter
      ref="dialogSettingsFilter"
      :user-list="userList"
      :search-form="searchForm"
      :search-form-text="searchFormText"
      :filter-patterns="filterPatterns"
      @add="$emit('add-pattern', $event)"
      @save="$emit('save-pattern',$event)"
      @delete="$emit('delete-pattern',$event)"
    />
    <vue-perfect-scrollbar class="h-without-header sweeep-filter">
      <v-btn
        small
        depressed
        class="mt-3 ml-3"
        @click="onClickClearButton"
      >
        <s-icon
          icon="feather-x-circle"
          color="gray-700"
          size="xl"
          class="mr-2"
          v-text="'フィルタパターン設定'"
        />
        クリア
      </v-btn>
      <v-btn
        small
        depressed
        class="mt-3 ml-3"
        @click="showEditDisplayFilterPatternsDialog"
      >
        <s-icon
          icon="feather-settings"
          color="gray-700"
          size="xl"
          class="mr-2"
          v-text="'フィルタパターン設定'"
        />
        設定
      </v-btn>
      <v-autocomplete
        v-model="selectedFilterPattern"
        return-object
        hide-details
        dense
        outlined
        label="フィルタパターン"
        class="ma-3 more-dense"
        :items="displayFilterPatterns"
        item-text="name"
        item-value="id"
        clearable
      >
        <template v-slot:item="data">
          <span style="font-size: 10px">
            {{ data.item.name }}
          </span>
          <v-spacer />
          <v-badge
            :value="computed_filter_result_count(data.item) >= 0"
            overlap
            :color="
              computed_filter_result_count(data.item) > 0 ? 'red' : ''
            "
          >
            <span
              v-show="computed_filter_result_count(data.item) > 0"
              slot="badge"
              style="display: block; transform: scale(0.8)"
              v-text="
                computed_filter_result_count(data.item) > 99
                  ? '99+'
                  : computed_filter_result_count(data.item)
              "
            />
          </v-badge>
        </template>
      </v-autocomplete>
      <v-expansion-panels flat tile multiple focusable style="box-shadow: none">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="fs-10 fw-bold">基本項目</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-3">
            <v-row no-gutters>
              <v-col cols="5">
                <v-text-field
                  v-model="searchForm.id_1"
                  label="伝票No."
                  class="align-right"
                  v-bind="textAttribute"
                />
              </v-col>
              <v-col cols="2">
                <div class="text-center pt-2">〜</div>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="searchForm.id_2"
                  label="伝票No."
                  class="align-right"
                  v-bind="textAttribute"
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-text-field
                :value="searchForm.upload_source"
                :label="searchFormText.upload_source.label"
                v-bind="textAttribute"
                clearable
                @input="searchForm.upload_source = $event || ''"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.upload_type"
                :items="searchFormText.upload_type.items"
                :label="searchFormText.upload_type.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-col cols="9">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="searchForm.reading_date_from"
                      label="読取日付"
                      v-bind="textAttribute"
                      readonly
                      clearable-icon="feather-x"
                      :clearable="searchForm.filterDateType !== 1"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="searchForm.reading_date_from"
                    locale="jp-ja"
                    no-title
                    :day-format="(date) => new Date(date).getDate()"
                    @input="
                      updateHeaderDate('from', 1, searchForm.reading_date_from)
                    "
                  />
                </v-menu>
              </v-col>
              <v-col cols="3">
                <div class="fs-10 pt-3 pl-3">から</div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="9">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="searchForm.reading_date_to"
                      label="読取日付"
                      v-bind="textAttribute"
                      readonly
                      clearable-icon="feather-x"
                      :clearable="searchForm.filterDateType !== 1"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="searchForm.reading_date_to"
                    locale="jp-ja"
                    no-title
                    :day-format="(date) => new Date(date).getDate()"
                    @input="updateHeaderDate('to', 1, searchForm.reading_date_to)"
                  />
                </v-menu>
              </v-col>
              <v-col cols="3">
                <div class="fs-10 pt-3 pl-3">まで</div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="9">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="searchForm.date"
                      label="伝票日付"
                      v-bind="textAttribute"
                      readonly
                      clearable-icon="feather-x"
                      :clearable="searchForm.filterDateType !== 0"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="searchForm.date"
                    locale="jp-ja"
                    no-title
                    :day-format="(date) => new Date(date).getDate()"
                    @input="
                      updateHeaderDate('from', 0, searchForm.date)
                      disablesIssueDate()
                    "
                  />
                </v-menu>
              </v-col>
              <v-col cols="3">
                <div class="fs-10 pt-3 pl-3">から</div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="9">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="searchForm.date_to"
                      label="伝票日付"
                      v-bind="textAttribute"
                      readonly
                      clearable-icon="feather-x"
                      :clearable="searchForm.filterDateType !== 0"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="searchForm.date_to"
                    locale="jp-ja"
                    no-title
                    :day-format="(date) => new Date(date).getDate()"
                    @input="
                      updateHeaderDate('to', 0, searchForm.date_to)
                      disablesIssueDate()
                    "
                  />
                </v-menu>
              </v-col>
              <v-col cols="3">
                <div class="fs-10 pt-3 pl-3">まで</div>
              </v-col>
            </v-row>
            <v-row v-if="searchForm.filterDateType !== 0" no-gutters>
              <v-switch
                v-model="searchForm.settingsFilterBlank.issueDate"
                class="mt-0"
                color="primary"
                label="伝票日付未入力を検索"
                dense
                hide-details
                @change="inputFormIssueDate($event || false)"
              />
            </v-row>

            <v-row no-gutters>
              <v-col cols="9">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="searchForm.pay_date_from"
                      label="支払日付"
                      v-bind="textAttribute"
                      readonly
                      clearable-icon="feather-x"
                      :clearable="searchForm.filterDateType !== 2"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="searchForm.pay_date_from"
                    locale="jp-ja"
                    no-title
                    :day-format="(date) => new Date(date).getDate()"
                    @input="updateHeaderDate('from', 2, searchForm.pay_date_from)"
                  />
                </v-menu>
              </v-col>
              <v-col cols="3">
                <div class="fs-10 pt-3 pl-3">から</div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="9">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="searchForm.pay_date_to"
                      label="支払日付"
                      v-bind="textAttribute"
                      readonly
                      clearable-icon="feather-x"
                      :clearable="searchForm.filterDateType !== 2"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="searchForm.pay_date_to"
                    locale="jp-ja"
                    no-title
                    :day-format="(date) => new Date(date).getDate()"
                    @input="updateHeaderDate('to', 2, searchForm.pay_date_to)"
                  />
                </v-menu>
              </v-col>
              <v-col cols="3">
                <div class="fs-10 pt-3 pl-3">まで</div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-text-field
                :value="searchForm.company_name"
                :label="searchFormText.company_name.label"
                v-bind="textAttribute"
                clearable
                @input="searchForm.company_name = $event || ''"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.department"
                :items="[
                  {
                    name: '未指定',
                    code: 'zn8qm40qrx',
                    name_code: '未指定 zn8qm40qrx',
                  },
                  ...masterList.department,
                ]"
                :label="searchFormText.department.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.pay_type"
                :items="searchFormText.pay_type.items"
                :label="searchFormText.pay_type.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.gensenzei"
                :items="searchFormText.gensenzei.items"
                :label="searchFormText.gensenzei.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              />
            </v-row>

            <v-row v-if="displaySetting.description" no-gutters>
              <v-text-field
                :value="searchForm.description"
                :label="searchFormText.description.label"
                v-bind="textAttribute"
                clearable
                @input="searchForm.description = $event || ''"
              />
            </v-row>
            <v-row v-if="displaySetting.free_text_1" no-gutters>
              <v-text-field
                :value="searchForm.freeText1"
                :label="searchFormText.freeText1.label"
                v-bind="textAttribute"
                clearable
                @input="searchForm.freeText1 = $event || ''"
              />
            </v-row>
            <v-row v-if="displaySetting.free_text_2" no-gutters>
              <v-text-field
                :value="searchForm.freeText2"
                :label="searchFormText.freeText2.label"
                v-bind="textAttribute"
                clearable
                @input="searchForm.freeText2 = $event || ''"
              />
            </v-row>

            <v-row no-gutters>
              <v-col cols="5">
                <v-text-field
                  v-model="searchForm.amount_1"
                  :label="searchFormText.amount_1.label"
                  v-bind="textAttribute"
                  class="align-right"
                />
              </v-col>
              <v-col cols="2">
                <div class="text-center pt-2">〜</div>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="searchForm.amount_2"
                  :label="searchFormText.amount_2.label"
                  v-bind="textAttribute"
                  class="align-right"
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.scoreChoice"
                :items="searchFormText.scoreChoice.items"
                :label="searchFormText.scoreChoice.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.transactionChoice"
                :items="searchFormText.transactionChoice.items"
                :label="searchFormText.transactionChoice.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.deletedStatusChoice"
                :items="searchFormText.deletedStatusChoice.items"
                :label="searchFormText.deletedStatusChoice.label"
                filled
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
              />
            </v-row>
            <v-row no-gutters>
              <v-autocomplete
                :value="searchForm.readStatusChoice"
                :items="searchFormText.readStatusChoice.items"
                :label="searchFormText.readStatusChoice.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
                @input="searchForm.readStatusChoice = $event || 0"
              />
            </v-row>
            <v-row no-gutters>
              <v-autocomplete
                :value="searchForm.approve_status"
                :items="searchFormText.approve_status.items"
                :label="searchFormText.approve_status.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
                @input="searchForm.approve_status = $event || 0"
              />
            </v-row>
            <v-row no-gutters>
              <v-autocomplete
                :value="searchForm.pay_approve_status"
                :items="searchFormText.pay_approve_status.items"
                :label="searchFormText.pay_approve_status.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
                @input="searchForm.pay_approve_status = $event || 0"
              />
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="fs-10 fw-bold">ステータス／ユーザ</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="fs-10 fw-bold my-3">仕訳</div>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.exported"
                :items="searchFormText.exported.items"
                :label="searchFormText.exported.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.updated"
                :items="searchFormText.updated.items"
                :label="searchFormText.updated.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-if="approveSettings.approve1.display"
                v-model="searchForm.approve1"
                :items="['承認済', '未承認']"
                :label="searchFormText.approve1.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="a_item"
                item-value="a_item"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-if="approveSettings.approve2.display"
                v-model="searchForm.approve2"
                :items="['承認済', '未承認']"
                :label="searchFormText.approve2.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="a_item"
                item-value="a_item"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-if="approveSettings.approve3.display"
                v-model="searchForm.approve3"
                :items="['承認済', '未承認']"
                :label="searchFormText.approve3.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="a_item"
                item-value="a_item"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-if="approveSettings.approve4.display"
                v-model="searchForm.approve4"
                :items="['承認済', '未承認']"
                :label="searchFormText.approve4.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="a_item"
                item-value="a_item"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.approve5"
                :items="['承認済', '未承認']"
                :label="searchFormText.approve5.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="a_item"
                item-value="a_item"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.upload_user"
                :items="userList"
                :label="searchFormText.upload_user.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="user.full_name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.update_user"
                :items="userList"
                :label="searchFormText.update_user.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="user.full_name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.approve_user"
                :items="userList"
                :label="searchFormText.approve_user.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="user.full_name"
                item-value="id"
              />
            </v-row>

            <div class="fs-10 fw-bold my-3">支払</div>

            <v-row no-gutters>
              <v-autocomplete
                v-if="payConfirmSettings.pay_confirm1.display"
                v-model="searchForm.pay_confirm1"
                :items="searchFormText.pay_confirm1.items"
                :label="searchFormText.pay_confirm1.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-if="payConfirmSettings.pay_confirm2.display"
                v-model="searchForm.pay_confirm2"
                :items="searchFormText.pay_confirm2.items"
                :label="searchFormText.pay_confirm2.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-if="payConfirmSettings.pay_confirm3.display"
                v-model="searchForm.pay_confirm3"
                :items="searchFormText.pay_confirm3.items"
                :label="searchFormText.pay_confirm3.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.pay_approve"
                :items="searchFormText.pay_approve.items"
                :label="searchFormText.pay_approve.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.paid"
                :items="['支払済', '未払']"
                :label="searchFormText.paid.label"
                filled
                outlined
                dense
                hide-details
                clearable
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.pay_approve_user"
                :items="userList"
                :label="searchFormText.pay_approve_user.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="user.full_name"
                item-value="id"
              />
            </v-row>

            <v-row no-gutters>
              <v-autocomplete
                v-model="searchForm.paid_user"
                :items="userList"
                :label="searchFormText.paid_user.label"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="user.full_name"
                item-value="id"
              />
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="fs-10 fw-bold">借方情報</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-6">
            <v-row v-if="displaySetting.account_title" no-gutters>
              <v-autocomplete
                :value="searchForm.debit_account_title"
                :items="computedMasterList.account_title"
                :label="searchFormText.debit_account_title.label"
                v-bind="journalAutocompleteAttrs"
                @change="changeAccountTitle($event, 'debit')"
              />
            </v-row>
            <v-row v-if="displaySetting.sub_account" no-gutters>
              <v-autocomplete
                v-model="searchForm.debit_sub_account"
                :items="debitSubAccountItems"
                :label="searchFormText.debit_sub_account.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.department" no-gutters>
              <v-autocomplete
                v-model="searchForm.debit_department"
                :items="computedMasterList.department"
                :label="searchFormText.debit_department.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.vendor" no-gutters>
              <v-autocomplete
                v-model="searchForm.debit_vendor"
                :items="computedMasterList.vendor"
                :label="searchFormText.debit_vendor.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.project" no-gutters>
              <v-autocomplete
                v-model="searchForm.debit_project"
                :items="computedMasterList.project"
                :label="searchFormText.debit_project.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.segment" no-gutters>
              <v-autocomplete
                v-model="searchForm.debit_segment"
                :items="computedMasterList.segment"
                :label="searchFormText.debit_segment.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.walletable" no-gutters>
              <v-autocomplete
                v-model="searchForm.debit_walletable"
                :items="computedMasterList.walletable"
                :label="searchFormText.debit_walletable.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.item" no-gutters>
              <v-autocomplete
                v-model="searchForm.debit_item"
                :items="computedMasterList.item"
                :label="searchFormText.debit_item.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.tag" no-gutters>
              <v-autocomplete
                v-model="searchForm.debit_tag"
                :items="computedMasterList.tag"
                :label="searchFormText.debit_tag.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.tax" no-gutters>
              <v-autocomplete
                v-model="searchForm.debit_tax_class"
                :items="computedMasterList.tax"
                :label="searchFormText.debit_tax_class.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="fs-10 fw-bold">貸方情報</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-6">
            <v-row v-if="displaySetting.account_title" no-gutters>
              <v-autocomplete
                :value="searchForm.credit_account_title"
                :items="computedMasterList.account_title"
                :label="searchFormText.credit_account_title.label"
                v-bind="journalAutocompleteAttrs"
                @change="changeAccountTitle($event, 'credit')"
              />
            </v-row>
            <v-row v-if="displaySetting.sub_account" no-gutters>
              <v-autocomplete
                v-model="searchForm.credit_sub_account"
                :items="creditSubAccountItems"
                :label="searchFormText.credit_sub_account.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.department" no-gutters>
              <v-autocomplete
                v-model="searchForm.credit_department"
                :items="computedMasterList.department"
                :label="searchFormText.credit_department.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.vendor" no-gutters>
              <v-autocomplete
                v-model="searchForm.credit_vendor"
                :items="computedMasterList.vendor"
                :label="searchFormText.credit_vendor.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.project" no-gutters>
              <v-autocomplete
                v-model="searchForm.credit_project"
                :items="computedMasterList.project"
                :label="searchFormText.credit_project.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.segment" no-gutters>
              <v-autocomplete
                v-model="searchForm.credit_segment"
                :items="computedMasterList.segment"
                :label="searchFormText.credit_segment.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.walletable" no-gutters>
              <v-autocomplete
                v-model="searchForm.credit_walletable"
                :items="computedMasterList.walletable"
                :label="searchFormText.credit_walletable.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.item" no-gutters>
              <v-autocomplete
                v-model="searchForm.credit_item"
                :items="computedMasterList.item"
                :label="searchFormText.credit_item.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.tag" no-gutters>
              <v-autocomplete
                v-model="searchForm.credit_tag"
                :items="computedMasterList.tag"
                :label="searchFormText.credit_tag.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
            <v-row v-if="displaySetting.tax" no-gutters>
              <v-autocomplete
                v-model="searchForm.credit_tax_class"
                :items="computedMasterList.tax"
                :label="searchFormText.credit_tax_class.label"
                v-bind="journalAutocompleteAttrs"
              />
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DialogSettingsFilter from '@/views/invoice/components/InvoiceHeaderFilter/components/DialogSettingsFilter'

const textAttribute = Object.freeze({
  filled: true,
  outlined: true,
  dense: true,
  hideDetails: true,
})

const journalAutocompleteAttrs = Object.freeze({
  filled: true,
  outlined: true,
  dense: true,
  hideDetails: true,
  clearable: true,
  itemText: 'name',
  itemValue: 'name_code',
  returnObject: true,
})

export default {
  components: {
    DialogSettingsFilter,
  },
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    searchForm: {
      type: Object,
      required: true,
    },
    searchFormText: {
      type: Object,
      required: true,
    },
    approveSettings: {
      type: Object,
      required: true,
    },
    payConfirmSettings: {
      type: Object,
      required: true,
    },
    setLocalStorageSearchForm: {
      type: Function,
      required: true,
    },
    userList: {
      type: Array,
      required: true,
    },
    filterPatterns: {
      type: Array,
      required: true,
    },
    filterResultCount: {
      type: Object,
      required: true,
    },
    clearAllFilter: {
      type: Function,
      required: true,
    },
    setFilterPattern: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dialogSaveFilterPattern: false,
      account_list: [],
      blankAccountTitle: {
        name: '未入力を検索',
        id: -1,
        status: 1,
      },
      textAttribute,
      journalAutocompleteAttrs,
      selectedFilterPattern: {},
    }
  },
  computed: {
    ...mapGetters(['masterList', 'displaySetting', 'definition']),
    creditSubAccountItems() {
      const accountTitle = this.searchForm.credit_account_title
      return this.getSubAccountItems(accountTitle)
    },
    debitSubAccountItems() {
      const accountTitle = this.searchForm.debit_account_title
      return this.getSubAccountItems(accountTitle)
    },
    computedMasterList() {
      const masterList = this.masterList
      let showMasterList = {}
      Object.keys(masterList).forEach(
        (key) =>
          (showMasterList[key] = masterList[key].filter(
            (item) => item.status == 1
          ))
      )
      showMasterList['account_title'].unshift(this.blankAccountTitle)
      return showMasterList
    },
    displayFilterPatterns: function () {
      let patterns = this.filterPatterns.filter((x) => x.display)
      return patterns
    },
    computed_filter_result_count: function () {
      return function (item) {
        try {
          let short_name = item.name.substr(0, 3).trim()
          let key = ''
          switch (short_name) {
            case '今日':
              key = 'read_today'
              break
            case '最近':
              key = 'recent_days'
              break
            case '未更新':
              key = 'not_updated'
              break
            case '未仕訳':
              key = 'not_approved'
              break
            case '未支払':
              key = 'not_paid'
              break
            default:
              return -1
          }

          if (this.filterResultCount[key]) {
            return this.filterResultCount[key]
          } else {
            return 0
          }
        } catch (e) {
          return 0
        }
      }
    },
  },
  watch: {
    selectedFilterPattern: {
      handler: function (val) {
        if (val) {
          this.setSelectFilterPattern(val)
        } else {
          this.clearAllFilter()
        }
      },
    },
  },
  methods: {
    getSubAccountItems(accountTitle) {
      if (accountTitle) {
        return accountTitle.subjects.filter((item) => item.status == 1)
      } else {
        return []
      }
    },
    updateHeaderDate(mode, type, date) {
      try {
        if (this.searchForm.filterDateType === type) {
          if (mode === 'from') {
            this.searchForm.headerDate = date
          }
          if (mode === 'to') {
            this.searchForm.headerDateTo = date
          }
          this.$emit('change:header-date')
        }
      } catch (error) {
        console.log(error)
      }
    },
    disablesIssueDate() {
      this.searchForm.settingsFilterBlank.issueDate = false
    },
    inputFormIssueDate(event) {
      this.searchForm['date'] = null
      this.searchForm['date_to'] = null
      this.searchForm.settingsFilterBlank.issueDate = event
    },
    changeAccountTitle(item, entrySide) {
      const searchFormKey = `${entrySide}_account_title`
      const settingsKey = `${entrySide}AccountTitle`
      this.searchForm.settingsFilterBlank[settingsKey] = item && item.id === -1
      this.searchForm[searchFormKey] = item
    },
    setSelectFilterPattern(item) {
      this.selectedFilterPattern = item
      let id = this.selectedFilterPattern.id
      this.setFilterPattern(id)
    },
    onClickClearButton(){
      this.clearAllFilter()
      this.selectedFilterPattern = {}
    },
    showEditDisplayFilterPatternsDialog() {
      this.$refs.dialogSettingsFilter.open()
    }
  },
}
</script>
