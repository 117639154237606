<template>
  <v-container v-if="checkPrivilege('invoice:read')" fluid pa-0>
    <base-dialog
      ref="dialogBeforeLeave"
      :title="'確認'"
      :message="'現在、編集中の情報が破棄されますがよろしいでしょうか？'"
      :cancel-text="$t('message.no')"
      :submit-text="$t('message.yes')"
      @submit="leavePageRevokeEditable()"
      @cancel="cacheNext = {}"
    />
    <v-row
      no-gutters
      style="flex-wrap: nowrap; height: calc(100vh - 50px); overflow: hidden"
    >
      <v-col
        cols="1"
        class="flex-grow-0 flex-shrink-1"
        style="min-width: 200px"
        :style="{ display: displaySideFilter }"
      >
        <v-card tile outlined class="pa-0">
          <invoice-side-filter
            :on-click="toggleSideFilter"
            :search-form="searchForm"
            :search-form-text="searchFormText"
            :approve-settings="approveSettings"
            :pay-confirm-settings="payConfirmSettings"
            :resport-setting="resportSetting"
            :set-local-storage-search-form="setLocalStorageSearchForm"
            :user-list="user_list"
            :filter-patterns="filterPatterns"
            :filter-result-count="filterResultCount"
            :clear-all-filter="clearAllFilter"
            :set-filter-pattern="setFilterPattern"
            @change:header-date="getTableData"
            @add-pattern="addFilterPattern"
            @save-pattern="saveChangeFilterPattern"
            @delete-pattern="deleteSelectFilterPattern"
          />
        </v-card>
      </v-col>
      <v-col
        :cols="displaySideFilter == 'block' ? 10 : 11"
        class="flex-grow-1 flex-shrink-1"
        style="min-width: 60px; max-width: 100%"
      >
        <v-card tile outlined class="w-100">
          <invoice-header-filter
            ref="invoiceHeaderFilter"
            :on-click="toggleSideFilter"
            :search-form="searchForm"
            :search-form-text="searchFormText"
            :status-data="statusData"
            :switch-mode-detail="toggleModeDetail"
            :switch-mode-table="toggleModeTable"
            :filter-btn-target-month="filterBtnTargetMonth"
            :filter-btn-prev-month="filterBtnPrevMonth"
            :filter-btn-next-month="filterBtnNextMonth"
            :display-mode="displayMode"
            :get-table-data="getTableData"
            :get-status-data="getStatusData"
            :clear-all-filter="clearAllFilter"
            :filter-patterns="filterPatterns"
            :user-list="user_list"
            :filter-form-entered="filterFormEntered"
            @input:searchBar="onInputSearchbar($event)"
            @click:open="toggleSideFilter"
          />
        </v-card>
        <v-card tile outlined class="w-100">
          <invoice-table
            v-show="displayMode == 1"
            ref="invoiceTable"
            :definition="definition"
            :all-selected="allSelected"
            :table-loader="tableLoader"
            :table-data-filtered="tableDataFiltered"
            :remove-invoice="removeInvoice"
            :sort-params="sortParams"
            :sort-table-data="sortTableData"
            :set-previous-id-list="setPreviousIdList"
            :sort-table-data-same-as-previous="sortTableDataSameAsPrevious"
            :display_settings="display_settings"
            :get-subject-list="getSubjectList"
            :get-table-data="getTableData"
            :in_detail_value="in_detail_value"
            :search-form="searchForm"
            :switch-mode-detail="toggleModeDetail"
            :approve-settings="approveSettings"
            :pay-confirm-settings="payConfirmSettings"
            :resport-setting="resportSetting"
            :on-click-select-all="onClickSelectAll"
            :pay-types="payTypes"
            :freee-separate-approval="freeeSeparateApproval"
            :setting-foreign-currency="organization.settingForeignCurrency"
            @change:table-values="MultiChangeTableData($event)"
            @change:invoice-detail="updateInvoiceDetail"
            @change:invoice-note="updateInvoiceNote($event)"
          />
          <invoice-detail
            v-show="displayMode == 2"
            ref="invoiceDetail"
            :pay-types="payTypes"
            :table-loader="tableLoader"
            load-batch-size="loadBatchSize"
            :organization="organization"
            :display-setting="$store.getters.displaySetting"
            :definition="definition"
            :invoice-list="tableDataFiltered"
            :table-data="tableData"
            :mode="mode"
            :approve-level="approveLevel"
            :bank-data="bankData"
            :get-subject-list="getSubjectList"
            :tax-rate-data="taxRateData"
            :account-tax-data="accountTaxData"
            :display_settings="display_settings"
            :display-mode="displayMode"
            :remove-invoice="removeInvoice"
            :user="user"
            :next-approval-users="nextApprovalUsers"
            :loading_bank="loading_bank"
            :history_table_height="history_table_height"
            :side_filter_width="sideFilterWidth"
            :approve-settings="approveSettings"
            :pay-confirm-settings="payConfirmSettings"
            :resport-setting="resportSetting"
            :get-master="getMaster"
            :get-recent-use-list="getRecentUseList"
            :get-table-data="getTableData"
            :filter-result-count="filterResultCount"
            :learning-amount-menu-setting="learningAmountMenuSetting"
            :freee-separate-approval="freeeSeparateApproval"
            :user-bank-list="userBankList"
            class="mx-0"
            :style="{
              width: 'calc(' + invoiceWidth + 'vw - ' + sideFilterWidth + ')',
            }"
            :user-list="user_list"
            @deleted="removeInvoice"
            @restored="restoreInvoice"
            @change:form-invoice="onChangeFormInvoice($event)"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { consoleLog } from 'Helpers/helpers'
import { mapGetters } from 'vuex'
import {
  getInitialSearchForm,
  getApproveSettings,
  getPayConfirmSettings,
} from '@/helpers/invoice'
import { currencyToNumber } from 'Helpers/journal'
import Snackbar from 'Helpers/snackbar/index'
import InvoiceSideFilter from './components/InvoiceSideFilter'
import InvoiceHeaderFilter from './components/InvoiceHeaderFilter/InvoiceHeaderFilter'
import InvoiceTable from './components/InvoiceTable'
import InvoiceDetail from './InvoiceDetail'
import Vue from 'vue'
import axios from 'axios'
import Axios from 'axios'

import { sortTableData, sortTableDataByIdList } from 'Helpers/table'
import { formatDateWithHyphen } from 'Helpers/date'

const searchForm = getInitialSearchForm()

export default {
  components: {
    InvoiceHeaderFilter,
    InvoiceTable,
    InvoiceDetail,
    InvoiceSideFilter,
  },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    bankData: {
      type: Object,
      required: true,
    },
    getMaster: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      searchForm,
      in_detail_value: false,
      drawer_status_array: [],
      max_data_user_storage: 20,
      displaySideFilter: 'none',
      displayMode: 1,
      displayContentTable: 'block',
      displayContentDetail: 'none',

      loadBatchSize: 25,

      tableData: [],
      tableDataFiltered: [],
      allSelected: { status: false },

      tableLoader: true,
      invoiceWidth: 100,
      history_table_height: '400px',
      selected_from_parent: [],
      filterFromParam: {
        idFrom: '',
        idTo: '',
        issueDate: '',
      },
      filterFormEntered: false,

      statusData: [
        {
          issue_date: '',
          notUpdated: { count: 0, selected: '' },
          notApproved: { count: 0, selected: '' },
          notExported: { count: 0, selected: '' },
          notPayApproved: { count: 0, selected: '' },
          notPayConfirmed1: { count: 0, selected: '' },
          notPayConfirmed2: { count: 0, selected: '' },
          notPayConfirmed3: { count: 0, selected: '' },
          updated: { count: 0, selected: '' },
          approved: { count: 0, selected: '' },
          exported: { count: 0, selected: '' },
          payApproved: { count: 0, selected: '' },
          payConfirmed1: { count: 0, selected: '' },
          payConfirmed2: { count: 0, selected: '' },
          payConfirmed3: { count: 0, selected: '' },
        },
      ],

      approveSettings: {
        approve1: { name: '', name2: '', display: false },
        approve2: { name: '', name2: '', display: false },
        approve3: { name: '', name2: '', display: false },
        approve4: { name: '承認1', name2: '承1', display: true },
        approve5: { name: '承認2', name2: '承2', display: true },
      },
      payConfirmSettings: {
        pay_confirm1: { name: '確認1', name2: '確1', display: true },
        pay_confirm2: { name: '確認2', name2: '確2', display: true },
        pay_confirm3: { name: '確認3', name2: '確3', display: true },
      },

      taxRateData: {},
      accountTaxData: {},

      load_count: 0,

      mode: {
        name: 'book',
        edit: false,
        edit_pay: false,
      },
      loading_bank: false,

      display_settings: {
        account_title: '',
        department: '',
        subaccount: '',
        project: '',
        segment: '',
        vendor: '',
        item: '',
        tag: '',
        walletable: '',
        tax: '',
        isCounter: false,
        maxlen_description: 255,
        maxlen_free_text_1: 255,
        maxlen_free_text_2: 255,
      },

      searchFormText: {
        exported: {
          label: '仕訳出力',
          items: [
            { id: 0, name: '未出力' },
            { id: 1, name: '仕訳出力済' },
          ],
        },
        updated: {
          label: '仕訳更新',
          items: [
            { id: 0, name: '未更新' },
            { id: 1, name: '更新済み' },
          ],
        },

        reading_date_from: { label: '読取日付' },
        reading_date_to: { label: '読取日付' },
        date: { label: '伝票日付' },
        date_to: { label: '伝票日付' },
        pay_date_from: { label: '支払日付' },
        pay_date_to: { label: '支払日付' },

        company_name: { label: '請求元' },
        department: { label: '所管部門' },
        description: { label: '' },
        freeText1: { label: '' },
        freeText2: { label: '' },
        amount_1: { label: '金額' },
        amount_2: { label: '金額' },
        id_1: { label: '伝票No.' },
        id_2: { label: '伝票No.' },
        debit_amount: { label: '' },
        credit_amount: { label: '' },
        pay_type: {
          label: '支払方法',
          items: [
            { id: 1, name: '振込' },
            { id: 2, name: '引落' },
            { id: 3, name: 'クレジット' },
            { id: 4, name: '手形' },
            { id: 5, name: '現金' },
            { id: 6, name: '海外送金' },
            { id: 0, name: 'その他' },
          ],
        },
        gensenzei: {
          label: '源泉対象',
          items: [
            { id: 1, name: '源泉対象' },
            { id: 0, name: '対象外' },
          ],
        },
        transactionChoice: {
          label: '新規取引',
          items: [
            { id: 1, name: '新規取引' },
            { id: 2, name: '取引実績あり' },
          ],
        },
        deletedStatusChoice: {
          label: '削除ステータス',
          items: [
            { id: 1, name: '削除済み以外' },
            { id: 2, name: '削除済み' },
          ],
        },
        readStatusChoice: {
          label: '閲覧状態',
          items: [
            { id: 1, name: '未読' },
            { id: 2, name: '既読' },
          ],
        },
        scoreChoice: {
          label: '金額読取精度',
          items: [
            { id: 1, name: '高' },
            { id: 2, name: '普通' },
            { id: 3, name: '低' },
          ],
        },
        upload_source: { label: '請求書ファイル名' },

        approve_status: {
          label: '仕訳承認ステータス',
          items: [
            { id: 1, name: '承認済' },
            { id: 2, name: '未承認、承認中' },
          ],
        },
        pay_approve_status: {
          label: '支払承認ステータス',
          items: [
            { id: 1, name: '承認済' },
            { id: 2, name: '未承認、承認中' },
          ],
        },
        pay_confirm1: {
          label: '',
          items: [
            { id: 0, name: '未更新' },
            { id: 1, name: '更新済み' },
          ],
        },
        pay_confirm2: {
          label: '',
          items: [
            { id: 0, name: '未更新' },
            { id: 1, name: '更新済み' },
          ],
        },
        pay_confirm3: {
          label: '',
          items: [
            { id: 0, name: '未更新' },
            { id: 1, name: '更新済み' },
          ],
        },

        pay_approve: {
          label: '支払連携',
          items: [
            { id: 0, name: '未連携' },
            { id: 1, name: '連携済み' },
          ],
        },
        paid: { label: '支払済' },

        approve1: { label: '' },
        approve2: { label: '' },
        approve3: { label: '' },
        approve4: { label: '' },
        approve5: { label: '' },

        debit_account_title: { label: '' },
        debit_sub_account: { label: '' },
        debit_department: { label: '' },
        debit_vendor: { label: '' },
        debit_project: { label: '' },
        debit_segment: { label: '' },
        debit_walletable: { label: '' },
        debit_item: { label: '' },
        debit_tag: { label: '' },
        debit_tax_class: { label: '' },

        credit_account_title: { label: '' },
        credit_sub_account: { label: '' },
        credit_department: { label: '' },
        credit_vendor: { label: '' },
        credit_project: { label: '' },
        credit_segment: { label: '' },
        credit_walletable: { label: '' },
        credit_item: { label: '' },
        credit_tag: { label: '' },
        credit_tax_class: { label: '' },

        upload_type: {
          label: '読取経路',
          items: [
            { id: 1, name: '画面' },
            { id: 2, name: 'メール' },
          ],
        },
        upload_user: { label: '読取ユーザ', items: [] },
        update_user: { label: '更新ユーザ', items: [] },
        approve_user: { label: '承認ユーザ', items: [] },
        export_user: { label: '', items: [] },
        pay_export_user: { label: '', items: [] },
        pay_approve_user: { label: '支払連携ユーザ', items: [] },
        paid_user: { label: '支払ユーザ', items: [] },

        search_bar: { label: '簡単フィルタ' },

        //use only if ResportSetting is true
        project_local_bank_code: { label: '' },
        debit_project_local_bank_code: { label: '' },
        credit_project_local_bank_code: { label: '' },
      },

      sortParams: {
        value: 'id', // specify value name of table header
        mode: 'asc', //asc or desc
      },
      resportSetting: false,

      filterPatterns: [],
      payTypes: [
        { id: 1, name: '振込' },
        { id: 2, name: '引落' },
        { id: 3, name: 'クレジット' },
        { id: 4, name: '手形' },
        { id: 5, name: '現金' },
        { id: 6, name: '海外送金' },
        { id: 0, name: 'その他' },
      ],
      recentUseList: [],
      journalItems: {},
      journalItemsWithRecentUse: {},
      filterResultCount: {},
      user_list: [],
      nextApprovalUsers: [],
      cacheNext: {},
      previousIdList: [],
      userBankList: [],
      learningAmountMenuSetting: {
        sortMode: '',
        lowestDigitNumber: '',
        inputMode: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'definition',
      'freeeSeparateApproval',
      'approveLevel',
      'payConfirmLevel',
    ]),
    sideFilterWidth: function () {
      if (this.displaySideFilter == 'block') {
        return '200px'
      } else {
        return '0px'
      }
    },
  },
  watch: {
    'display_settings.department': function (newVal) {
      this.display_settings.department = newVal
    },
    'display_settings.subaccount': function (newVal) {
      this.display_settings.subaccount = newVal
    },
    'display_settings.project': function (newVal) {
      this.display_settings.project = newVal
    },
    'display_settings.segment': function (newVal) {
      this.display_settings.segment = newVal
    },
    'display_settings.vendor': function (newVal) {
      this.display_settings.vendor = newVal
    },
    searchForm: {
      handler: function () {
        this.setLocalStorageSearchForm()
        this.filterTableData()
        this.checkFilterFormEntered()

        // エラー回避
        if (this.$refs.invoiceTable === undefined) {
          return
        }
        this.$refs.invoiceTable.resetPage()
      },
      deep: true,
    },
    tableData: {
      handler: function () {
        this.filterTableData()
      },
      deep: true,
    },
    'allSelected.status': function (val) {
      this.onClickSelectAll(val)
    },
    tableDataFiltered: function () {
      this.clearSelected()
    },
    learningAmountMenuSetting: {
      handler() {
        localStorage.setItem(
          'learningAmountMenuSetting',
          JSON.stringify(this.learningAmountMenuSetting)
        )
      },
      deep: true,
    },
  },
  created() {
    this.getUserSession()
    this.getUserData()
    this.getUserBankList()
    this.getParameter()
    this.getLocalStorageSearchForm()
    this.getLocalStorageLearningAmountMenuSetting()
    this.getStatusData()
    this.setFilterFromParameter(this.$route.query)
    this.getTableData()
    this.getDrawerAndModeStatusData()
    this.getResportSetting()
    this.bulkCreateInvoicePayJournal()
    this.getFilterPatterns()
    this.getRecentUseList()
    this.setFormText()
  },
  beforeRouteUpdate(to, from, next) {
    this.setFilterFromParameter(to.query)
    next()
  },
  beforeRouteLeave(to, from, next) {
    const matchInvoiceRoute = to.fullPath.match(
      /^\/invoice\/((?:[^\/]+?))(?:\/(?=$))?$/i
    )
    if (this.$store.getters.editedInvoiceForm && !matchInvoiceRoute) {
      this.cacheNext = to
      next(false)
      this.$refs['dialogBeforeLeave'].open()
    } else {
      next()
    }
  },
  methods: {
    setFormText() {
      let formText = this.searchFormText
      const approveSettings = this.approveSettings
      const payConfirmSettings = this.payConfirmSettings
      const definition = this.definition

      formText.approve1.label = approveSettings.approve1.name
      formText.approve2.label = approveSettings.approve2.name
      formText.approve3.label = approveSettings.approve3.name
      formText.approve4.label = approveSettings.approve4.name
      formText.approve5.label = approveSettings.approve5.name
      formText.pay_confirm1.label = payConfirmSettings.pay_confirm1.name
      formText.pay_confirm2.label = payConfirmSettings.pay_confirm2.name
      formText.pay_confirm3.label = payConfirmSettings.pay_confirm3.name
      formText.debit_account_title.label = definition.account_title
      formText.debit_sub_account.label = definition.sub_account
      formText.debit_department.label = definition.department
      formText.debit_vendor.label = definition.vendor
      formText.debit_project.label = definition.project
      formText.debit_segment.label = definition.segment
      formText.debit_walletable.label = definition.walletable
      formText.debit_item.label = definition.item
      formText.debit_tag.label = definition.tag
      formText.debit_tax_class.label = definition.tax_class
      formText.credit_account_title.label = definition.account_title
      formText.credit_sub_account.label = definition.sub_account
      formText.credit_department.label = definition.department
      formText.credit_vendor.label = definition.vendor
      formText.credit_project.label = definition.project
      formText.credit_segment.label = definition.segment
      formText.credit_walletable.label = definition.walletable
      formText.credit_item.label = definition.item
      formText.credit_tag.label = definition.tag
      formText.credit_tax_class.label = definition.tax_class

      formText.description.label = definition.description
      formText.freeText1.label = definition.free_text_1
      formText.freeText2.label = definition.free_text_2
    },
    getParameter() {
      let paramUserOrgId = this.$route.query.userorganizationid
      let paramOrganizationId = this.$route.query.organizationid
      let paramModeDetail = this.$route.query.modedetail

      this.signin({ paramUserOrgId, paramOrganizationId })
      if (paramModeDetail) {
        this.toggleModeDetail()
      }
    },

    setFilterFromParameter(query) {
      const applyDate = (dateType, from, to) => {
        const typeFormKey = {
          issueDate: {
            filterDateType: 0,
            from: 'date',
            to: 'date_to',
          },
          readingDate: {
            filterDateType: 1,
            from: 'reading_date_from',
            to: 'reading_date_to',
          },
        }
        const formKey = typeFormKey[dateType]
        if (!formKey) {
          return
        }
        const datePattern = new RegExp(/[1-2][0-9]{3}-[0-1][0-9]-[0-3][0-9]/)
        const matchNeither = !(to.match(datePattern) || from.match(datePattern))
        if (matchNeither) {
          const today = formatDateWithHyphen(new Date())
          from = today
          to = today
        }
        this.searchForm.filterDateType = formKey['filterDateType']
        this.searchForm[formKey['from']] = from
        this.searchForm[formKey['to']] = to
        this.searchForm.headerDate = from
        this.searchForm.headerDateTo = to
      }

      const queryParams = Object.keys(query).filter((key) => query[key])
      if (queryParams.length === 0) {
        return
      }
      this.clearAllFilter()
      this.searchForm.date = null
      this.searchForm.date_to = null
      this.searchForm.reading_date_from = null
      this.searchForm.reading_date_to = null
      this.searchForm.pay_date_from = null
      this.searchForm.pay_date_to = null

      // key or function
      const queryParamFilterObject = {
        idfrom: 'id_1',
        idto: 'id_2',
        issuedate: applyDate,
        readingdate: applyDate,
        uploaduser: 'upload_user',
      }
      for (const param of queryParams) {
        const filterObject = queryParamFilterObject[param]
        if (typeof filterObject === 'string') {
          this.searchForm[filterObject] = query[param]
        } else if (param === 'issuedate') {
          filterObject('issueDate', query[param], query[param])
        } else if (param === 'readingdate') {
          filterObject('readingDate', query[param], query[param])
        }
      }
      Snackbar.success('フィルタが変更されました')
    },
    getFilterPatterns() {
      try {
        let url = this.$store.getters.apiFilterPatternsUrl
        let auth_token = this.$store.getters.getAuthToken
        let user_organization_id = this.$store.getters.getUserOrganizationID

        axios
          .get(url, {
            headers: { Authorization: auth_token },
            params: { user_organization_id: user_organization_id },
          })
          .then((response) => {
            if (response.data.error) {
              Snackbar.error(response.data.error)
            } else {
              let temp_data = response.data.data
              let hidden_patterns = response.data.hidden_patterns

              temp_data.forEach((obj) => {
                if (hidden_patterns.includes(obj.id)) {
                  obj.display = false
                } else {
                  obj.display = true
                }
                if (!obj.filter_pattern.deletedStatusChoice) {
                  obj.filter_pattern.deletedStatusChoice = 1
                }
              })
              temp_data.sort(function (a, b) {
                if (
                  a.user_organization == null &&
                  b.user_organization != null
                ) {
                  return -1
                } else if (
                  a.user_organization != null &&
                  b.user_organization == null
                ) {
                  return 1
                } else {
                  return 0
                }
              })
              this.filterPatterns = temp_data
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    addFilterPatternToList(filterPattern) {
      filterPattern.display = true
      this.filterPatterns.push(filterPattern)
    },
    deleteFilterPatternFromList(filterPattern) {
      let index = this.filterPatterns.findIndex((x) => x.id == filterPattern.id)
      this.filterPatterns.splice(index, 1)
    },
    setFilterPattern(filterPatternId) {
      try {
        let filterPatternList = this.filterPatterns.filter((pattern) => {
          return pattern.id == filterPatternId
        })
        if (!filterPatternList.length) {
          return
        }
        let user_organization_id = this.$store.getters.getUserOrganizationID
        let pattern = filterPatternList[0]
        if (
          pattern.days_from_today_issue_date != null ||
          pattern.days_from_today_reading_date != null ||
          pattern.days_from_today_pay_date != null
        ) {
          this.filterBtnTargetToday(
            pattern.filter_pattern.filterDateType,
            pattern.days_from_today_issue_date,
            pattern.days_from_today_reading_date,
            pattern.days_from_today_pay_date
          )
        } else {
          const tempHeaderDate = this.searchForm.headerDate
          const tempHeaderDateTo = this.searchForm.headerDateTo
          const tempFilterDateType = this.searchForm.filterDateType
          const selectedFilterPattern = JSON.parse(
            JSON.stringify(filterPatternList[0].filter_pattern)
          )
          let tempFilter = getInitialSearchForm()

          //set value if key existed
          for (let key in tempFilter) {
            if (key in selectedFilterPattern) {
              tempFilter[key] = selectedFilterPattern[key]
            }
          }

          if (tempFilter.upload_user === 0) {
            tempFilter.upload_user = user_organization_id
          }
          if (tempFilter.update_user === 0) {
            tempFilter.update_user = user_organization_id
          }
          if (tempFilter.approve_user === 0) {
            tempFilter.approve_user = user_organization_id
          }
          if (tempFilter.export_user === 0) {
            tempFilter.export_user = user_organization_id
          }
          if (tempFilter.pay_export_user === 0) {
            tempFilter.pay_export_user = user_organization_id
          }
          if (tempFilter.pay_approve_user === 0) {
            tempFilter.pay_approve_user = user_organization_id
          }
          if (tempFilter.paid_user === 0) {
            tempFilter.paid_user = user_organization_id
          }

          this.searchForm = JSON.parse(JSON.stringify(tempFilter))
          this.$set(this.searchForm, 'headerDate', tempHeaderDate)
          this.$set(this.searchForm, 'headerDateTo', tempHeaderDateTo)
          this.$set(this.searchForm, 'filterDateType', tempFilterDateType)
          this.$set(this.searchForm, 'date', null)
          this.$set(this.searchForm, 'date_to', null)
          this.$set(this.searchForm, 'reading_date_from', null)
          this.$set(this.searchForm, 'reading_date_to', null)
          this.$set(this.searchForm, 'pay_date_from', null)
          this.$set(this.searchForm, 'pay_date_to', null)
          switch (this.searchForm.filterDateType) {
            case 0:
              this.$set(this.searchForm, 'date', this.searchForm.headerDate)
              this.$set(
                this.searchForm,
                'date_to',
                this.searchForm.headerDateTo
              )
              this.$set(this.searchForm.settingsFilterBlank, 'issueDate', false)
              break
            case 1:
              this.$set(
                this.searchForm,
                'reading_date_from',
                this.searchForm.headerDate
              )
              this.$set(
                this.searchForm,
                'reading_date_to',
                this.searchForm.headerDateTo
              )
              break
            case 2:
              this.$set(
                this.searchForm,
                'pay_date_from',
                this.searchForm.headerDate
              )
              this.$set(
                this.searchForm,
                'pay_date_to',
                this.searchForm.headerDateTo
              )
              break
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    sortTableData(headerValue, mode = null) {
      sortTableData(headerValue, mode, this.sortParams, this.tableData)
    },
    setPreviousIdList() {
      this.previousIdList = this.tableData.map((item) => item.image_local_id)
    },
    sortTableDataSameAsPrevious() {
      try {
        sortTableDataByIdList(
          this.previousIdList,
          'image_local_id',
          this.tableData
        )
      } catch (e) {
        console.log(e)
      }
    },
    clearAllFilter() {
      try {
        return new Promise((resolve) => {
          let ignore_keys = ['headerDate', 'headerDateTo', 'filterDateType']
          if (this.searchForm.filterDateType === 0) {
            ignore_keys.push('date')
            ignore_keys.push('date_to')
          } else if (this.searchForm.filterDateType === 1) {
            ignore_keys.push('reading_date_from')
            ignore_keys.push('reading_date_to')
          } else {
            ignore_keys.push('pay_date_from')
            ignore_keys.push('pay_date_to')
          }
          const initalForm = getInitialSearchForm()
          for (let key of Object.keys(this.searchForm)) {
            if (ignore_keys.includes(key) === false) {
              this.$set(this.searchForm, key, initalForm[key])
            }
          }
          this.setLocalStorageSearchForm
          resolve(true)
        })
      } catch (e) {
        console.log(e)
      }
    },
    bulkCreateInvoicePayJournal() {
      try {
        axios
          .put(
            this.$store.getters.apiInvoicePayJournalBulkCreate,
            {
              organization_id: this.$store.getters.getOrganizationID,
            },
            { headers: { Authorization: this.$store.getters.getAuthToken } }
          )
          .then((response) => {
            if (response.data.error) {
              Snackbar.error(response.data.error)
            } else {
              if (response.data.count > 0) {
                Snackbar.success('Created Pay Journals')
              }
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    getSubjectList(obj) {
      try {
        let subjects = this.journalItems.account_title.find(
          (item) => item.id == obj.id
        ).subjects
        return subjects
      } catch (e) {
        return []
      }
    },
    getUserSession() {
      try {
        this.display_settings.account_title =
          this.organization.settingsAccountTitle
        this.display_settings.department = this.organization.settingsDepartment
        this.display_settings.project = this.organization.settingsProject
        this.display_settings.segment = this.organization.settingsSegment
        this.display_settings.subaccount = this.organization.settingsSubAccount
        this.display_settings.vendor = this.organization.settingsVendor
        this.display_settings.item = this.organization.settingsItem
        this.display_settings.tag = this.organization.settingsTag
        this.display_settings.walletable = this.organization.settingsWalletable
        this.display_settings.description =
          this.organization.settingsDescription
        this.display_settings.free_text_1 = this.organization.settingsFreeText1
        this.display_settings.free_text_2 = this.organization.settingsFreeText2
        this.display_settings.tax = this.organization.settingsTax
        this.display_settings.isCounter =
          this.organization.wordOrByte == 1 ? true : false
        this.display_settings.maxlen_description =
          this.organization.maxLenDescription
        this.display_settings.maxlen_free_text_1 =
          this.organization.maxLenFreeText1
        this.display_settings.maxlen_free_text_2 =
          this.organization.maxLenFreeText2
        this.approveSettings = getApproveSettings(this.approveLevel)
        this.payConfirmSettings = getPayConfirmSettings(this.payConfirmLevel)
      } catch (err) {
        console.log(err)
      }
    },
    getStatusData() {
      try {
        Axios.get(this.$store.getters.apiInvoiceStatusUrl, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
          params: {
            organization_id: this.$store.getters.getOrganizationID,
          },
        }).then((response) => {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            this.statusData = response.data.result
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    onClickSelectAll(val) {
      if (val) {
        for (let item of this.tableDataFiltered) {
          item.selected = true
        }
      } else {
        for (let item of this.tableDataFiltered) {
          item.selected = false
        }
      }
    },
    clearSelected() {
      let idListFiltered = this.tableDataFiltered.map((obj) => obj.id)

      for (let item of this.tableData) {
        if (item.selected && idListFiltered.indexOf(item.id) < 0) {
          item.selected = false
        }
      }
    },
    filterTableData() {
      // エラー回避
      if (this.$refs.invoiceTable === undefined) {
        return
      }
      this.tableDataFiltered = this.$refs.invoiceTable.customFilter(
        this.tableData,
        this.searchForm,
        '',
        [],
        this.searchForm.settingsFilterBlank
      )
    },
    async getTableData() {
      let start = new Date()
      try {
        this.tableLoader = true
        if (!this.searchForm.headerDate || !this.searchForm.headerDateTo) {
          //searchForm.headerDateが存在しないと、apiが叩けずload中になる
          this.initHeaderDate()
        }
        this.setLocalStorageSearchForm()
        let response = await Axios.get(
          this.$store.getters.apiInvoiceListFunction,
          {
            headers: { Authorization: this.$store.getters.getAuthToken },
            params: {
              mode: 'table',
              organization_id: this.$store.getters.getOrganizationID,
              date_from: this.searchForm.headerDate,
              date_to: this.searchForm.headerDateTo,
              filter_date_type: this.searchForm.filterDateType,
            },
          }
        )
        if (response.data.error) {
          Snackbar.error(response.data.error)
        } else {
          this.tableData = response.data.data.map(invoice => {
            invoice.approved1 = invoice.date_approve1 ? 1: 0
            invoice.approved2 = invoice.date_approve2 ? 1: 0
            invoice.approved3 = invoice.date_approve3 ? 1: 0
            invoice.approved4 = invoice.date_approve4 ? 1: 0
            invoice.approved = invoice.date_approve5 ? 1: 0
            return invoice
          })
          this.filterResultCount = response.data.data_count
          this.allSelected.status = false

          this.tableLoader = false

          setTimeout(() => {
            if (this.$route.params.id) {
              Vue.nextTick(() => {
                let el = $('.selected-element').position()
                if (el) {
                  $('.invoice-sidebar-scroll').scrollTop(el.top)
                }
              })
            }
          })
        }
        consoleLog('API', 'getTableData', start)
        return response
      } catch (e) {
        console.log(e)
      }
    },
    removeInvoice(invoiceId) {
      try {
        this.tableData.forEach((item) => {
          if (item.id == invoiceId) {
            item.is_deleted = true
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    restoreInvoice(invoiceId) {
      try {
        this.tableData.forEach((item) => {
          if (item.id == invoiceId) {
            item.is_deleted = false
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatDate_firstDay(date) {
      try {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + 1,
          year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
      } catch (e) {
        console.log(e)
      }
    },
    formatDate_lastDay(date) {
      try {
        let d = new Date(date)
        let month = '' + (d.getMonth() + 1)
        let year = d.getFullYear()
        let day = '' + d.getDate()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
      } catch (e) {
        console.log(e)
      }
    },
    getDaysInMonth(year, month) {
      try {
        return new Date(year, month, 0).getDate()
      } catch (e) {
        console.log(e)
      }
    },
    filterBtnPrevMonth() {
      try {
        let t = this
        if (t.searchForm.headerDate) {
          let val_from = new Date(t.searchForm.headerDate)
          let from_date = val_from.setMonth(val_from.getMonth() - 1)
          let formatted_date = this.formatDate_firstDay(from_date)
          this.searchForm.headerDate = formatted_date
        }
        if (t.searchForm.headerDateTo) {
          let date_to = new Date(t.searchForm.headerDateTo)
          let newData = new Date(
            date_to.getFullYear(),
            date_to.getMonth() - 0,
            0
          )
          this.searchForm.headerDateTo = this.formatDate_lastDay(newData)
        }
        this.setFilterDate()
        this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    filterBtnNextMonth() {
      try {
        let t = this
        if (t.searchForm.headerDate) {
          let val_from = new Date(t.searchForm.headerDate)
          let from_date = val_from.setMonth(val_from.getMonth() + 1)
          let formatted_date = this.formatDate_firstDay(from_date)
          this.searchForm.headerDate = formatted_date
        }

        if (t.searchForm.headerDateTo) {
          let date_to = new Date(t.searchForm.headerDateTo)
          let newData = new Date(
            date_to.getFullYear(),
            date_to.getMonth() + 2,
            0
          )
          this.searchForm.headerDateTo = this.formatDate_lastDay(newData)
        }
        this.setFilterDate()
        this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    filterBtnTargetMonth() {
      try {
        let value = new Date()
        let month = value.getMonth() - 1
        let year = value.getFullYear()
        let days = this.getDaysInMonth(year, month + 1)
        let prevMonthDate = new Date(year, month, days)
        let formatted_date = this.formatDate_firstDay(prevMonthDate)
        this.searchForm.headerDate = formatted_date

        let newData = new Date()
        newData.setDate(days)
        this.searchForm.headerDateTo = this.formatDate_lastDay(prevMonthDate)
        this.setFilterDate()
        this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    filterBtnTargetToday(filter_type, issue_date, reading_date, pay_date) {
      try {
        this.clearAllFilter()
        this.$set(this.searchForm, 'date', null)
        this.$set(this.searchForm, 'date_to', null)
        this.$set(this.searchForm, 'pay_date_from', null)
        this.$set(this.searchForm, 'pay_date_to', null)
        this.$set(this.searchForm, 'reading_date_from', null)
        this.$set(this.searchForm, 'reading_date_to', null)

        if (reading_date != null) {
          if (reading_date > 0) {
            this.$set(
              this.searchForm,
              'reading_date_from',
              this.getDateFromToday(0)
            )
            this.$set(
              this.searchForm,
              'reading_date_to',
              this.getDateFromToday(reading_date)
            )
          } else {
            this.$set(
              this.searchForm,
              'reading_date_from',
              this.getDateFromToday(reading_date)
            )
            this.$set(
              this.searchForm,
              'reading_date_to',
              this.getDateFromToday(0)
            )
          }
        }
        if (issue_date != null) {
          if (issue_date > 0) {
            this.$set(this.searchForm, 'date', this.getDateFromToday(0))
            this.$set(
              this.searchForm,
              'date_to',
              this.getDateFromToday(issue_date)
            )
          } else {
            this.$set(
              this.searchForm,
              'date',
              this.getDateFromToday(issue_date)
            )
            this.$set(this.searchForm, 'date_to', this.getDateFromToday(0))
          }
        }
        if (pay_date != null) {
          if (pay_date > 0) {
            this.$set(
              this.searchForm,
              'pay_date_from',
              this.getDateFromToday(0)
            )
            this.$set(
              this.searchForm,
              'pay_date_to',
              this.getDateFromToday(pay_date)
            )
          } else {
            this.$set(
              this.searchForm,
              'pay_date_from',
              this.getDateFromToday(pay_date)
            )
            this.$set(this.searchForm, 'pay_date_to', this.getDateFromToday(0))
          }
        }

        this.searchForm.filterDateType = filter_type
        switch (filter_type) {
          case 0:
            this.searchForm.headerDate = this.searchForm.date
            this.searchForm.headerDateTo = this.searchForm.date_to
            break
          case 1:
            this.searchForm.headerDate = this.searchForm.reading_date_from
            this.searchForm.headerDateTo = this.searchForm.reading_date_to
            break
          case 2:
            this.searchForm.headerDate = this.searchForm.pay_date_from
            this.searchForm.headerDateTo = this.searchForm.pay_date_to
            break
        }

        this.setFilterDate()
        this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    getDateFromToday(days) {
      try {
        let value = new Date()
        value.setDate(value.getDate() + days)
        let year = value.getFullYear()
        let monthTemp = value.getMonth() + 1
        let month = ('00' + monthTemp).slice(-2)
        let day = ('00' + value.getDate()).slice(-2)
        let result = [year, month, day].join('-')
        return result
      } catch (e) {
        console.log(e)
      }
    },
    filterBtnPresets(type) {
      try {
        this.clearAllFilter()
        switch (type) {
          case 'not_updated':
            this.searchForm.updated = 0
            break
          case 'not_approved':
            this.searchForm.approve5 = '未承認'
            break
          case 'not_paid':
            this.searchForm.paid = '未払'
            break
        }
      } catch (e) {
        console.log(e)
      }
    },
    signin(payload) {
      let user_organization_id = payload.paramUserOrgId
      let organization_id = payload.paramOrganizationId

      if (!user_organization_id && !organization_id) {
        return
      }
      if (user_organization_id == this.$store.getters.getUserOrganizationID) {
        return
      }
      if (organization_id == this.$store.getters.getOrganizationID) {
        return
      }
      try {
        let confirmUrl = this.$store.getters.apiConfirmSignInAnother

        axios
          .get(confirmUrl, {
            headers: { Authorization: this.$store.getters.getAuthToken },
            params: {
              user_organization_id: user_organization_id,
              organization_id: organization_id,
            },
          })
          .then((response) => {
            if (response.data.status == 'success' && response.data.can_signin) {
              let apiUrl = this.$store.getters.apiSignInAnother
              let token = this.$store.getters.getAuthToken
              let envtype = this.$store.getters.getEnvType
              user_organization_id = response.data.user_organization_id

              this.$store
                .dispatch('apiSignInAnother', {
                  user_organization_id,
                  envtype,
                  apiUrl,
                  token,
                })
                .then((response) => {
                  if (response.data.error) {
                    Snackbar.error(response.data.error)
                  }
                })
            } else {
              Snackbar.error(response.data.error)
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    toggleModeDetail(item = null) {
      try {
        this.displayMode = 2
        this.setLocalStorageDisplayMode()
        if (item) {
          this.$router.push('/invoice/' + item.image_local_id)
        }
      } catch (e) {
        console.log(e)
      }
    },
    toggleModeTable() {
      try {
        this.displayMode = 1
        this.setLocalStorageDisplayMode()
      } catch (e) {
        console.log(e)
      }
    },
    setLocalStorageDisplayMode() {
      try {
        let user_id = this.$store.getters.getUserID
        this.drawer_status_array = JSON.parse(
          localStorage.getItem('drawer_status')
        )
        if (!this.drawer_status_array) {
          this.drawer_status_array = []
        }
        let foundIndex = null
        for (let x = 0; x < this.drawer_status_array.length; x++) {
          if (this.drawer_status_array[x].user_id == user_id) {
            foundIndex = x
          }
        }
        if (foundIndex != null) {
          this.drawer_status_array[foundIndex].display_mode = this.displayMode
          localStorage.setItem(
            'drawer_status',
            JSON.stringify(this.drawer_status_array)
          )
        } else {
          if (this.drawer_status_array.length == this.max_data_user_storage) {
            let found_input_index = null
            for (let x = 0; x < this.drawer_status_array.length; x++) {
              if (this.drawer_status_array[x].last_input == true) {
                found_input_index = x
              }
              this.drawer_status_array[x].last_input = false
            }
            let data = {
              user_id: user_id,
              display_mode: this.displayMode,
              last_input: true,
            }
            if (found_input_index == this.max_data_user_storage - 1) {
              found_input_index = 0
            } else {
              found_input_index += 1
            }
            if (found_input_index != null) {
              this.drawer_status_array[found_input_index] = data
            }
            localStorage.setItem(
              'drawer_status',
              JSON.stringify(this.drawer_status_array)
            )
          } else {
            for (let x = 0; x < this.drawer_status_array.length; x++) {
              this.drawer_status_array[x].last_input = false
            }
            let data = {
              user_id: user_id,
              display_mode: this.displayMode,
              last_input: true,
            }
            this.drawer_status_array.push(data)
            localStorage.setItem(
              'drawer_status',
              JSON.stringify(this.drawer_status_array)
            )
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    getDrawerAndModeStatusData() {
      try {
        let user_id = this.$store.getters.getUserID
        let data = JSON.parse(localStorage.getItem('drawer_status'))
        let foundIndex = null
        if (data) {
          for (let x = 0; x < data.length; x++) {
            if (data[x].user_id == user_id) {
              foundIndex = x
            }
          }
          if (foundIndex != null) {
            this.invoiceWidth = 100
            this.displayMode = data[foundIndex].display_mode
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    setLocalStorageDrawerStatus(display) {
      try {
        let user_id = this.$store.getters.getUserID
        this.drawer_status_array = JSON.parse(
          localStorage.getItem('drawer_status')
        )
        if (!this.drawer_status_array) {
          this.drawer_status_array = []
        }
        let foundIndex = null
        for (let x = 0; x < this.drawer_status_array.length; x++) {
          if (this.drawer_status_array[x].user_id == user_id) {
            foundIndex = x
          }
        }
        if (foundIndex != null) {
          this.drawer_status_array[foundIndex].status = display
          localStorage.setItem(
            'drawer_status',
            JSON.stringify(this.drawer_status_array)
          )
        } else {
          if (this.drawer_status_array.length == this.max_data_user_storage) {
            let found_input_index = null
            for (let x = 0; x < this.drawer_status_array.length; x++) {
              if (this.drawer_status_array[x].last_input == true) {
                found_input_index = x
              }
              this.drawer_status_array[x].last_input = false
            }
            let data = {
              user_id: user_id,
              status: display,
              display_mode: this.displayMode,
              last_input: true,
            }
            if (found_input_index == this.max_data_user_storage - 1) {
              found_input_index = 0
            } else {
              found_input_index += 1
            }
            if (found_input_index != null) {
              this.drawer_status_array[found_input_index] = data
            }
            localStorage.setItem(
              'drawer_status',
              JSON.stringify(this.drawer_status_array)
            )
          } else {
            for (let x = 0; x < this.drawer_status_array.length; x++) {
              this.drawer_status_array[x].last_input = false
            }
            let data = {
              user_id: user_id,
              status: display,
              display_mode: this.displayMode,
              last_input: true,
            }
            this.drawer_status_array.push(data)
            localStorage.setItem(
              'drawer_status',
              JSON.stringify(this.drawer_status_array)
            )
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    toggleSideFilter() {
      try {
        if (this.displaySideFilter == 'none') {
          this.displaySideFilter = 'block'
          this.invoiceWidth = 100
          this.setLocalStorageDrawerStatus('block')
        } else {
          this.displaySideFilter = 'none'
          this.invoiceWidth = 100
          this.setLocalStorageDrawerStatus('none')
        }
      } catch (e) {
        console.log(e)
      }
    },
    getResportSetting() {
      try {
        axios
          .get(this.$store.getters.apiResportSettingUrl, {
            headers: { Authorization: this.$store.getters.getAuthToken },
            params: { org_id: this.$store.getters.getOrganizationID },
          })
          .then((response) => {
            if (response.data.status == 'success') {
              this.resportSetting = response.data.resport_setting
            } else {
              Snackbar.error(response.data.error)
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    initHeaderDate() {
      try {
        let now = new Date()
        let lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0)

        let yyyy = lastMonthEnd.getFullYear()
        let mm = ('0' + (lastMonthEnd.getMonth() + 1)).slice(-2)
        let dd = ('0' + lastMonthEnd.getDate()).slice(-2)

        this.searchForm.headerDate = yyyy + '-' + mm + '-' + '01'
        this.searchForm.headerDateTo = yyyy + '-' + mm + '-' + dd
        this.searchForm.filterDateType = 0
      } catch (e) {
        console.log(e)
      }
    },
    setLocalStorageSearchForm() {
      try {
        localStorage.setItem('searchForm', JSON.stringify(this.searchForm))
      } catch (e) {
        console.log(e)
      }
    },
    getLocalStorageSearchForm() {
      try {
        // 初めてのユーザはローカルストレージにsearchFormが入っていない
        if (localStorage.getItem('searchForm') === null) {
          this.setLocalStorageSearchForm()
        } else {
          let searchForm = JSON.parse(localStorage.getItem('searchForm'))
          this.searchForm = { ...this.searchForm, ...searchForm }
          if (this.searchForm.filterDateType === undefined) {
            this.searchForm.filterDateType = 0
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    getLocalStorageLearningAmountMenuSetting() {
      let storageSetting = localStorage.getItem('learningAmountMenuSetting')
      if (storageSetting == undefined) {
        this.learningAmountMenuSetting.sortMode = 'position'
        this.learningAmountMenuSetting.sortMode = 'exclude'
        this.learningAmountMenuSetting.lowestDigitNumber = 3
        localStorage.setItem(
          'learningAmountMenuSetting',
          JSON.stringify(this.learningAmountMenuSetting)
        )
      } else {
        storageSetting = JSON.parse(storageSetting)
        this.learningAmountMenuSetting.sortMode = storageSetting.sortMode
        this.learningAmountMenuSetting.inputMode = storageSetting.inputMode
        this.learningAmountMenuSetting.lowestDigitNumber = Number(
          storageSetting.lowestDigitNumber
        )
      }
    },
    setFilterDate() {
      switch (this.searchForm.filterDateType) {
        case 0:
          this.searchForm.date = this.searchForm.headerDate
          this.searchForm.date_to = this.searchForm.headerDateTo
          break
        case 1:
          this.searchForm.reading_date_from = this.searchForm.headerDate
          this.searchForm.reading_date_to = this.searchForm.headerDateTo
          break
        case 2:
          this.searchForm.pay_date_from = this.searchForm.headerDate
          this.searchForm.pay_date_to = this.searchForm.headerDateTo
      }
    },
    getRecentUseList() {
      try {
        let url = this.$store.getters.apiRecentUseListUrl
        let auth_token = this.$store.getters.getAuthToken
        let organization_id = this.$store.getters.getOrganizationID

        axios
          .get(url, {
            headers: { Authorization: auth_token },
            params: { organization_id: organization_id },
          })
          .then((response) => {
            if (response.data.error) {
              Snackbar.error(response.data.error)
            } else {
              this.recentUseList = response.data.data
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    createJournalItemsWithRecentUse() {
      this.initializeJournalItemWithRecentUse()
      let sortByDate = this.recentUseList.concat()
      sortByDate.sort(function (a, b) {
        return a.date_updated < b.date_updated ? 1 : -1
      })

      for (let key in this.journalItemsWithRecentUse) {
        let relatedJournalKey = this.getRelatedJournalKey(key)
        let obj
        obj = this.getLatestJournal(sortByDate, key, relatedJournalKey)
        if (obj) {
          this.journalItemsWithRecentUse[key].push(obj)
        }
        let arr
        arr = this.getModeJournals(key, relatedJournalKey)
        for (let i = 0; i < arr.length; i++) {
          this.journalItemsWithRecentUse[key].push(arr[i])
        }
        arr = this.journalItems[relatedJournalKey].slice()
        for (let i = 0; i < arr.length; i++) {
          this.journalItemsWithRecentUse[key].push(arr[i])
        }
      }
    },
    getModeJournals(keyName, relatedJournalKey) {
      //RecentUseListから各項目の頻度が高い値TOP4を集計
      try {
        let counter = {}
        for (let i = 0; i < this.recentUseList.length; i++) {
          let key = this.recentUseList[i][keyName].id
          if (key) {
            if (counter[key]) {
              counter[key].count = counter[key].count + 1
            } else {
              counter[key] = {}
              counter[key].count = 1
              counter[key].data = Object.assign(
                {},
                this.journalItems[relatedJournalKey].find(
                  (obj) => obj.id == key
                )
              )
              counter[key].data.name_code =
                '[頻出] ' + counter[key].data.name_code
              if (counter[key].data.rate_name_code) {
                counter[key].data.rate_name_code =
                  '[頻出] ' + counter[key].data.rate_name_code
              }
            }
          }
        }
        let sortByFrequency = []
        for (let key in counter) {
          if (counter[key].data.id) {
            sortByFrequency.push(counter[key].data)
          }
        }
        sortByFrequency.sort(function (a, b) {
          counter[b.id].count - counter[a.id].count
        })
        return sortByFrequency.slice(0, 4)
      } catch (e) {
        console.log(e)
      }
    },
    getLatestJournal(sortedList, keyName, relatedJournalKey) {
      try {
        let latestData
        for (let i = 0; i < sortedList.length; i++) {
          if (sortedList[i][keyName].id) {
            latestData = Object.assign(
              {},
              this.journalItems[relatedJournalKey].find(
                (obj) => obj.id == sortedList[i][keyName].id
              )
            )
            break
          }
        }
        if (latestData) {
          latestData.name_code = '[最新]  ' + latestData.name_code
          if (latestData.rate_name_code) {
            latestData.rate_name_code = '[最新]  ' + latestData.rate_name_code
          }
        }
        return latestData
      } catch (e) {
        console.log(e)
      }
    },
    initializeJournalItemWithRecentUse() {
      this.journalItemsWithRecentUse = {
        debit_account_title: [],
        debit_department: [],
        debit_vendor: [],
        debit_project: [],
        debit_segment: [],
        debit_walletable: [],
        debit_item: [],
        debit_tag: [],
        debit_tax: [],

        credit_account_title: [],
        credit_department: [],
        credit_vendor: [],
        credit_project: [],
        credit_segment: [],
        credit_walletable: [],
        credit_item: [],
        credit_tag: [],
        credit_tax: [],
      }
    },
    getRelatedJournalKey(detailKey) {
      let key
      switch (detailKey) {
        case 'debit_account_title':
        case 'credit_account_title':
          key = 'account_title'
          break
        case 'debit_department':
        case 'credit_department':
          key = 'department'
          break
        case 'debit_vendor':
        case 'credit_vendor':
          key = 'vendor'
          break
        case 'debit_project':
        case 'credit_project':
          key = 'project'
          break
        case 'debit_segment':
        case 'credit_segment':
          key = 'segment'
          break
        case 'debit_walletable':
        case 'credit_walletable':
          key = 'walletable'
          break
        case 'debit_item':
        case 'credit_item':
          key = 'item'
          break
        case 'debit_tag':
        case 'credit_tag':
          key = 'tag'
          break
        case 'debit_tax':
        case 'credit_tax':
          key = 'tax'
          break
      }
      return key
    },
    getUserData() {
      try {
        const auth_token = this.$store.getters.getAuthToken
        const organization_id = this.$store.getters.getOrganizationID
        const user_organization_id = this.$store.getters.getUserOrganizationID
        axios
          .get(this.$store.getters.apiUserListFunction, {
            headers: { Authorization: auth_token },
            params: { organization_id: organization_id, user_role_id: 1 },
          })
          .then((response) => {
            if (response.data.error) {
              Snackbar.error(response.data.error)
            } else {
              /*
                {
                  "id": userOrg.id,
                  "name": userOrg.user.full_name
                  "department":
                }
              */
              this.user_list = response.data.user_list
              this.nextApprovalUsers = response.data.next_approval_users
              let index = this.user_list.findIndex(
                (x) => x.id === user_organization_id
              )
              let own_user = Object.assign({}, this.user_list[index])
              if (own_user.user && own_user.user.full_name) {
                own_user.user.full_name = '自分'
                this.user_list.splice(index, 1)
                this.user_list.unshift(own_user)
              }
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    onChangeFormInvoice(event) {
      const imageSummaryId = event.imageSummaryId
      const index = this.tableData.findIndex(
        (data) => data.id === imageSummaryId
      )
      const attributes = event.attributes
      this.changeTableDataAttr({ index, attributes })
    },
    MultiChangeTableData(payload) {
      try {
        const DictTableIndex = {}
        this.tableData.forEach((row, i) => {
          DictTableIndex[row.id] = i
        })
        payload.forEach((object) => {
          let imageSummaryId = object.imageSummaryId
          let index =
            DictTableIndex[imageSummaryId] !== undefined
              ? DictTableIndex[imageSummaryId]
              : -1
          let attributes = object.attributes
          this.changeTableDataAttr({ index, attributes })
        })
      } catch (e) {
        console.log(e)
      }
    },
    changeTableDataAttr(payload) {
      const index = payload.index
      if (index === -1) {
        void 0
      }
      const instance = this.tableData[index]
      const attributes = payload.attributes
      Object.keys(attributes).forEach((key) => {
        this.$set(instance, key, attributes[key])
      })
    },
    onInputSearchbar(event) {
      this.searchForm.search_bar = event
      this.filterTableData()

      if (this.$refs.invoiceHeaderFilter !== undefined) {
        this.$refs.invoiceHeaderFilter.checkFilterFormEntered()
      }
      this.setLocalStorageSearchForm()
    },
    leavePageRevokeEditable() {
      try {
        this.$store.commit('setEditedInvoiceFormFields', [])
        this.$router.push(this.cacheNext.path)
      } catch (error) {
        console.log(error)
      }
    },
    updateInvoiceDetail() {
      this.$refs.invoiceDetail.getInvoiceDetail(false)
    },
    updateInvoiceNote(event) {
      this.$refs.invoiceDetail.updateNote(event)
    },
    getUserBankList() {
      this.$store.dispatch('getUserBankList').then((data) => {
        this.userBankList = data
      })
    },
    currencyToNumber(amount) {
      if (amount === '') {
        return ''
      } else {
        return currencyToNumber(amount)
      }
    },
    updateHeaderDate() {
      try {
        let date_from = ''
        let date_to = ''
        if (this.searchForm.filterDateType === 1) {
          date_from = this.searchForm.reading_date_from
          date_to = this.searchForm.reading_date_to
        } else if (this.searchForm.filterDateType === 0) {
          date_from = this.searchForm.date
          date_to = this.searchForm.date_to
        } else {
          date_from = this.searchForm.pay_date_from
          date_to = this.searchForm.pay_date_to
        }

        if (date_from !== null) {
          this.searchForm.headerDate = date_from
        }
        if (date_to !== null) {
          this.searchForm.headerDateTo = date_to
        }
      } catch (error) {
        console.log(error)
      }
    },
    addFilterPattern(addItem) {
      if (!addItem.name) {
        Snackbar.error('パターン名は必須です')
        return
      }
      try {
        let url = this.$store.getters.apiFilterPatternsUrl
        let data = {
          name: addItem.name,
          filter_pattern: addItem.filterPattern,
          user_organization_id: this.$store.getters.getUserOrganizationID,
        }
        axios
          .post(url, data, {
            headers: { Authorization: this.$store.getters.getAuthToken },
          })
          .then((response) => {
            if (response.data.status == 'success') {
              this.addFilterPatternToList(response.data.data)
              Snackbar.success('パターンを追加しました')
            } else {
              Snackbar.error(response.data.error)
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    saveChangeFilterPattern(editItem) {
      try {
        let url = this.$store.getters.apiFilterPatternsUrl
        let data = {
          id: editItem.id,
          name: editItem.name,
          display: editItem.display,
          user_organization_id: this.$store.getters.getUserOrganizationID,
        }
        axios
          .patch(url, data, {
            headers: { Authorization: this.$store.getters.getAuthToken },
          })
          .then((response) => {
            if (response.data.status == 'success') {
              for (const [index, pattern] of this.filterPatterns.entries()) {
                if (pattern.id === editItem.id) {
                  this.$set(this.filterPatterns, index, editItem)
                  break
                }
              }
              Snackbar.success('パターンを変更しました')
            } else {
              Snackbar.error(response.data.error)
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    deleteSelectFilterPattern(patternId) {
      try {
        let id = parseInt(patternId)
        let user_organization_id = this.$store.getters.getUserOrganizationID
        let url = this.$store.getters.apiFilterPatternsUrl
        let data = {
          id: id,
          user_organization_id: user_organization_id,
        }
        axios
          .delete(url, {
            data: data,
            headers: { Authorization: this.$store.getters.getAuthToken },
          })
          .then((response) => {
            if (response.data.status == 'success') {
              for (const [index, pattern] of this.filterPatterns.entries()) {
                if (pattern.id === patternId) {
                  this.filterPatterns.splice(index, 1)
                  break
                }
              }
              Snackbar.success('パターンを削除しました')
            } else {
              Snackbar.error(response.data.error)
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    checkFilterFormEntered() {
      try {
        let ignoreKeys = [
          'headerDate',
          'headerDateTo',
          'filterDateType',
          'settingsFilterBlank',
        ]
        if (this.searchForm.filterDateType === 0) {
          ignoreKeys.push('date')
          ignoreKeys.push('date_to')
        } else if (this.searchForm.filterDateType === 1) {
          ignoreKeys.push('reading_date_from')
          ignoreKeys.push('reading_date_to')
        } else {
          ignoreKeys.push('pay_date_from')
          ignoreKeys.push('pay_date_to')
        }
        if (this.searchForm.deletedStatusChoice == 1) {
          ignoreKeys.push('deletedStatusChoice')
        }
        const defaultFilter = getInitialSearchForm()
        this.filterFormEntered = Object.entries(this.searchForm).some(
          ([key, value]) => {
            const changed =
              !ignoreKeys.includes(key) &&
              defaultFilter[key] !== value &&
              (value || value == 0)
            return changed
          }
        )
      } catch (e) {
        this.filterFormEntered = false
      }
    },
  },
}
</script>
