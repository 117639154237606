<template>
  <v-dialog v-model="dialog" scrollable max-width="1000px">
    <v-card class="sweeep-dialog">
      <v-card-text class="pa-5">
        <div class="py-4">タイムスタンプの検証を行います。</div>

        <v-data-table
          :headers="headers"
          :items="items"
          height="calc(100vh - 240px)"
          class="px-4"
          style="width: 100%"
        >
          <template>
            <select-all-header-cell :items="items" />
          </template>

          <template #item="{ item, isSelected }">
            <tr :class="isSelected ? 'v-data-table__selected' : ''">
              <td>
                <span v-text="item.localId" />
              </td>
              <td>
                <span v-text="item.companyName" />
              </td>
              <td>
                <span v-text="getIsValidMessage(item.isValid)" />
              </td>
              <td>
                <span v-text="item.invalidDetail" />
              </td>
            </tr>
          </template>

          <template #no-results>
            <v-alert :value="true" color="yellow" icon="warning" class="mt-3">
              {{ $t('message.noResult') }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="close"> キャンセル </v-btn>
        <v-btn color="primary" :loading="loading" @click="submit"> 実行 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getValidMessage } from './_helpers/texts'

export default {
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: '伝票No', diplay: true, value: 'localId' },
        { text: '請求元', display: true, value: 'companyName' },
        { text: '検証結果', display: true, value: 'isValid' },
        { text: '詳細', display: true, value: 'invalidDetail' },
      ],
      tableItems: [],
    }
  },
  computed: {},
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$emit('cancel')
    },
    submit() {
      this.$emit('submit')
    },
    getIsValidMessage(isValid) {
      return getValidMessage(isValid)
    },
  },
}
</script>
