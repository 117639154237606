<template>
  <base-dialog
    ref="dialog"
    :scrollable="true"
    :title="$t('message.invoice_bulk_editing')"
    :cancel-text="$t('message.modal_cancel_btn')"
    :submit-text="$t('message.save')"
    @submit="submit()"
  >
    <template #card-text>
      <div class="mb-5">変更された項目だけ編集の対象になります。</div>
      <v-form ref="form_multiple_edit">
        <v-row>
          <v-col cols="3">
            <date-pick-input
              v-model="edit_journal_form.multiple_date"
              label="変更後の伝票日付"
              :flat="false"
            />
          </v-col>

          <v-col cols="3">
            <date-pick-input
              v-model="edit_journal_form.multiple_pay_date"
              label="変更後の支払日付"
              :flat="false"
            />
          </v-col>

          <v-col cols="6">
            <v-autocomplete
              v-model="edit_journal_form.pay_type"
              :items="payTypes"
              label="支払方法"
              placeholder=" "
              item-text="name"
              item-value="id"
              class="small-clearable pl-4"
              tabindex="1"
              clearable
              hide-details
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="edit_journal_form.company_name"
              label="請求元"
              placeholder=" "
              tabindex="1"
              hide-details
            />
          </v-col>
        </v-row>

        <v-row class="py-0">
          <v-col cols="4" />
          <v-col cols="5">
            <v-checkbox
              v-model="edit_journal_form.edit_gensen_data"
              hide-details
              label="源泉対象データを編集"
              :disabled="disabledEditGensenData"
            />
          </v-col>
          <v-col cols="3" />
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-autocomplete
              v-model="edit_journal_form.gensenzei"
              :items="[
                { text: '源泉対象', value: true },
                { text: '源泉対象外', value: false },
              ]"
              label="源泉対象"
              placeholder=" "
              item-text="text"
              item-value="value"
              class="small-clearable"
              clearable
              hide-details
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="edit_journal_form.gensen_total_targets"
              v-tooltip="''"
              label="対象人数"
              placeholder=" "
              type="number"
              class="inputPrice align-right fs-10"
              :disabled="!edit_journal_form.edit_gensen_data"
              hide-detail
              :rules="[
                (v) => Number(v) >= 0 || '0以上の数値を入力してください',
              ]"
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="edit_journal_form.gensen_code"
              label="源泉の区分"
              placeholder=" "
              type="text"
              :items="$store.getters.gensenType"
              item-text="name"
              item-value="code"
              class="small-clearable"
              clearable
              style="white-space: nowrap"
              :disabled="!edit_journal_form.edit_gensen_data"
            />
          </v-col>
        </v-row>

        <journal-form-header no-padding />
        <v-row no-gutters>
          <template v-for="dc in ['debit', 'credit']">
            <v-col :key="`dropzone_${dc}_journal`" cols="6">
              <v-card
                tile
                outlined
                class="pb-3"
                style="background-color: #fcfcfc"
              >
                <div class="px-2 pt-2">
                  <journal-iterators
                    :journal="edit_journal_form"
                    :editable="true"
                    :entry-side="dc"
                    @change="onChangeJournal($event, edit_journal_form)"
                  />
                </div>
              </v-card>
            </v-col>
          </template>
        </v-row>
        <!-- 摘要 -->
        <v-card
          v-if="showJournalTextArea"
          tile
          outlined
          class="pa-3"
          style="background-color: #fcfcfc"
        >
          <v-row v-if="displaySetting.description" no-gutters>
            <journal-text-field
              v-model="edit_journal_form.description"
              description-field
              :chip="sliceText(definition.description, 0, 4)"
              :tooltip="definition.description"
              :editable="true"
              :mode="displaySetting.description"
              :counter="displaySetting.maxlen_description"
              :check-byte="displaySetting.wordOrByte == 2"
            />
          </v-row>
          <v-row v-if="displaySetting.free_text_1" no-gutters>
            <journal-text-field
              v-model="edit_journal_form.free_text_1"
              :chip="sliceText(definition.free_text_1, 0, 4)"
              :tooltip="definition.free_text_1"
              :editable="true"
              :mode="displaySetting.free_text_1"
              :counter="displaySetting.maxlen_free_text_1"
              :check-byte="displaySetting.wordOrByte == 2"
            />
          </v-row>
          <v-row v-if="displaySetting.free_text_2" no-gutters>
            <journal-text-field
              v-model="edit_journal_form.free_text_2"
              :chip="sliceText(definition.free_text_2, 0, 4)"
              :tooltip="definition.free_text_2"
              :editable="true"
              :mode="displaySetting.free_text_2"
              :counter="displaySetting.maxlen_free_text_2"
              :check-byte="displaySetting.wordOrByte == 2"
            />
          </v-row>
          <v-row no-gutters>
            <v-textarea
              v-model="edit_journal_form.note_1"
              label="管理メモ（仕訳には反映されません）"
              placeholder=" "
              outlined
              class="thin-border fs-14 white"
              :rules="[
                (v) =>
                  v.length < 255 || '255' + $t('message.character_max_length'),
              ]"
              tabindex="9999"
              hide-details
            />
          </v-row>
        </v-card>
      </v-form>
    </template>
  </base-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { sliceText, onChangeJournal } from 'Helpers/journal'
import bulkEditHelper from 'Helpers/invoice/bulkUpdate'

import DatePickInput from 'Components/Input/DatePickInput'
import JournalIterators from 'Components/Journal/JournalIterators.vue'
import JournalFormHeader from 'Components/Journal/JournalFormHeader.vue'
import JournalTextField from 'Components/Journal/JournalTextField.vue'

const edit_journal_form = bulkEditHelper.getForm()
export default {
  components: {
    DatePickInput,
    JournalIterators,
    JournalFormHeader,
    JournalTextField,
  },
  props: {
    definition: {
      type: Object,
      required: true,
    },
    payTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      edit_journal_form,
      dialog: false,
    }
  },
  computed: {
    ...mapGetters(['displaySetting', 'showJournalTextArea']),
    disabledEditGensenData: function () {
      return this.edit_journal_form.gensenzei === false
    },
  },
  watch: {
    'edit_journal_form.debit_account_title': function () {
      this.edit_journal_form.debit_sub_account = {}
    },
    'edit_journal_form.credit_account_title': function () {
      this.edit_journal_form.credit_sub_account = {}
    },
    'edit_journal_form.gensenzei': function (val) {
      if (val) {
        this.edit_journal_form.edit_gensen_data = true
      } else {
        this.edit_journal_form.edit_gensen_data = false
        this.edit_journal_form.gensen_total_targets = ''
        this.edit_journal_form.gensen_code = ''
      }
    },
    'edit_journal_form.edit_gensen_data': function (val) {
      if (!val) {
        this.edit_journal_form.gensen_total_targets = ''
        this.edit_journal_form.gensen_code = ''
      }
    },
  },
  methods: {
    open() {
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
    submit() {
      this.$emit('submit', this.edit_journal_form)
    },
    sliceText(text, i, h) {
      return sliceText(text, i, h)
    },
    onChangeJournal(event, journal) {
      try {
        onChangeJournal(event, journal)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
