/*
const bulkEditInfo = {
  issueDate:'',
  payDate:'',
  companyName: '',
  gensenzei: null,
  gensenTotalTargets:'',
  gensenCode:'',
  payType: '',
  note_1: '',

  debitAccountTitle: {},
  debitSubAccount: {},
  debitDepartment: {},
  debitVendor: {},
  debitProject: {},
  debitSegment: {},
  debitItem: {},
  debitTag: {},
  debitWalletable: {},
  debitTax: {},

  creditAccountTitle: {},
  creditSubAccount: {},
  creditDepartment: {},
  creditVendor: {},
  creditProject: {},
  creditSegment: {},
  creditItem: {},
  creditTag: {},
  creditWalletable: {},
  creditTax: {},
  description: '',
  free_text_1: '',
  free_text_2: ''
}
*/

export const bulkEditForm = {
  unupdatableFieldJournalApproved: [
    'multiple_date',

    'debit_account_title',
    'debit_sub_account',
    'debit_department',
    'debit_vendor',
    'debit_project',
    'debit_segment',
    'debit_item',
    'debit_tag',
    'debit_walletable',
    'debit_tax',

    'credit_account_title',
    'credit_sub_account',
    'credit_department',
    'credit_vendor',
    'credit_project',
    'credit_segment',
    'credit_item',
    'credit_tag',
    'credit_walletable',
    'credit_tax',

    'description',
    'free_text_1',
    'free_text_2',
  ],
  unupdatableFieldPaymentApproved: [
    'multiple_pay_date',
    'company_name',
    'gensenzei',
    'gensen_total_targets',
    'gensen_code',
    'pay_type',
  ],
  form: {
    multiple_date: '',
    multiple_pay_date: '',
    company_name: '',

    gensenzei: '',
    gensen_total_targets: '',
    gensen_code: '',
    pay_type: '',

    debit_account_title: {},
    debit_sub_account: {},
    debit_department: {},
    debit_vendor: {},
    debit_project: {},
    debit_segment: {},
    debit_item: {},
    debit_tag: {},
    debit_walletable: {},
    debit_tax: {},

    credit_account_title: {},
    credit_sub_account: {},
    credit_department: {},
    credit_vendor: {},
    credit_project: {},
    credit_segment: {},
    credit_item: {},
    credit_tag: {},
    credit_walletable: {},
    credit_tax: {},

    description: '',

    free_text_1: '',
    free_text_2: '',
    note_1: '',
    edit_gensen_data: false,
  },
}
