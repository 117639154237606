<template>
  <v-dialog v-model="dialog" max-width="900">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="'ステータスチェック'" />
      <v-card-text>
        <v-row no-gutters align="start">
          <v-select
            v-model="dateFilter.initialDate"
            :items="dateItems"
            menu-props="auto"
            outlined
            filled
            dense
            class="more-dense"
            style="max-width: 120px"
          />
          <span class="px-3 pt-2">〜</span>

          <v-select
            v-model="dateFilter.finalDate"
            :items="dateItems"
            menu-props="auto"
            outlined
            filled
            dense
            class="more-dense"
            style="max-width: 120px"
          />

          <v-spacer />

          <base-alert>
            1件以上あるセルをクリックするとデータを選択できます。
          </base-alert>
        </v-row>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="statusData"
          :items-per-page="-1"
          item-key="issue_date"
          fixed-header
          hide-default-footer
          class="sweeep-status-table"
        >
          <template v-slot:item.issue_date="{ item }">
            <div class="px-2">
              <span v-if="item.issue_date">
                {{ item.issue_date }}
              </span>
              <span v-else> 日付なし </span>
            </div>
          </template>

          <template v-slot:item.monthTotal="{ item }">
            <div
              class="status-data-cell"
              @click="onSelect(item, 'monthTotal')"
              @dblclick="onClick"
            >
              {{ item.monthTotal.count }}
              <span v-show="item.monthTotal.selected">
                <s-icon class="tw-float-left" icon="feather-check" />
              </span>
            </div>
          </template>

          <template v-slot:item.notUpdated="{ item }">
            <div
              class="status-data-cell"
              @click="onSelect(item, 'notUpdated')"
              @dblclick="onClick"
            >
              {{ item.notUpdated.count }}
              <span v-show="item.notUpdated.selected">
                <s-icon class="tw-float-left" icon="feather-check" />
              </span>
            </div>
          </template>

          <template v-slot:item.updated="{ item }">
            <div
              class="status-data-cell"
              @click="onSelect(item, 'updated')"
              @dblclick="onClick"
            >
              {{ item.updated.count }}
              <span v-show="item.updated.selected">
                <s-icon class="tw-float-left" icon="feather-check" />
              </span>
            </div>
          </template>

          <template v-slot:item.notApproved="{ item }">
            <div
              class="status-data-cell"
              @click="onSelect(item, 'notApproved')"
              @dblclick="onClick"
            >
              {{ item.notApproved.count }}
              <span v-show="item.notApproved.selected">
                <s-icon class="tw-float-left" icon="feather-check" />
              </span>
            </div>
          </template>

          <template v-slot:item.approved="{ item }">
            <div
              class="status-data-cell"
              @click="onSelect(item, 'approved')"
              @dblclick="onClick"
            >
              {{ item.approved.count }}
              <span v-show="item.approved.selected">
                <s-icon class="tw-float-left" icon="feather-check" />
              </span>
            </div>
          </template>

          <template v-slot:item.notExported="{ item }">
            <div
              class="status-data-cell"
              @click="onSelect(item, 'notExported')"
              @dblclick="onClick"
            >
              {{ item.notExported.count }}
              <span v-show="item.notExported.selected">
                <s-icon class="tw-float-left" icon="feather-check" />
              </span>
            </div>
          </template>

          <template v-slot:item.exported="{ item }">
            <div
              class="status-data-cell"
              @click="onSelect(item, 'exported')"
              @dblclick="onClick"
            >
              {{ item.exported.count }}
              <span v-show="item.exported.selected">
                <s-icon class="tw-float-left" icon="feather-check" />
              </span>
            </div>
          </template>

          <template v-slot:item.notPayApproved="{ item }">
            <div
              class="status-data-cell"
              @click="onSelect(item, 'notPayApproved')"
              @dblclick="onClick"
            >
              {{ item.notPayApproved.count }}
              <span v-show="item.notPayApproved.selected">
                <s-icon class="tw-float-left" icon="feather-check" />
              </span>
            </div>
          </template>

          <template v-slot:item.payApproved="{ item }">
            <div
              class="status-data-cell"
              @click="onSelect(item, 'payApproved')"
              @dblclick="onClick"
            >
              {{ item.payApproved.count }}
              <span v-show="item.payApproved.selected">
                <s-icon class="tw-float-left" icon="feather-check" />
              </span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="close()">
          {{ $t('message.modal_close_btn') }}
        </v-btn>
        <v-btn color="primary" @click="onClick">
          <span> 選択したデータを表示 </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'

export default {
  props: {
    statusData: {
      type: Array,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: '伝票月',
          value: 'issue_date',
          sortable: false,
          filter: this.filterByIssueDate,
        },
        { text: '読取済み', value: 'monthTotal', sortable: false },
        { text: '未更新', value: 'notUpdated', sortable: false },
        { text: '更新済み', value: 'updated', sortable: false },
        { text: '未承認', value: 'notApproved', sortable: false },
        { text: '承認済み', value: 'approved', sortable: false },
        { text: '仕訳未出力', value: 'notExported', sortable: false },
        { text: '仕訳出力済', value: 'exported', sortable: false },
        { text: '支払未連携', value: 'notPayApproved', sortable: false },
        { text: '支払連携済', value: 'payApproved', sortable: false },
      ],
      selected: [],
      dateItems: [],
      dateFilter: {
        initialDate: null,
        finalDate: null,
        show: true,
      },
    }
  },
  watch: {
    dateFilter: {
      handler: function () {
        localStorage.setItem('invoiceStatus', JSON.stringify(this.dateFilter))
      },
      deep: true,
    },
  },
  methods: {
    open() {
      this.dialog = true
      this.statusData.forEach((v) => {
        if (v.issue_date !== '') this.dateItems.push(v.issue_date)
      })
      try {
        this.loadLocalStorage()
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.clearAllSelect().then(() => {
        this.dialog = false
      })
    },
    setBackGround(selected) {
      return selected ? 'background-color:rgb(220,220,255) !important' : ''
    },
    clearAllSelect() {
      try {
        this.selectedItem.date = ''
        this.selectedItem.name = ''
        this.selectedItem.count = ''

        var statusData = this.statusData
        var names = [
          'notUpdated',
          'notApproved',
          'notExported',
          'notPayApproved',
          'updated',
          'approved',
          'exported',
          'payApproved',
          'monthTotal',
        ]

        return new Promise(function (resolve) {
          for (var item of statusData) {
            for (var name of names) {
              item[name].selected = false
            }
          }
          resolve(true)
        })
      } catch (e) {
        console.log(e)
      }
    },
    onSelect(item, name) {
      if (item.issue_date && item[name].count) {
        this.clearAllSelect().then(() => {
          item[name].selected = true
          this.selectedItem.date = item.issue_date
          this.selectedItem.name = name
          this.selectedItem.count = item[name].count
        })
      }
    },
    onClick() {
      if (this.selectedItem.name) {
        this.$emit('onClick')
      } else {
        Snackbar.error('データを選択してください')
      }
    },
    filterByIssueDate(value) {
      const search = this.dateFilter
      if (value === '') {
        return search.show
      }
      let issueDate = new Date(value.split('-')[0], value.split('-')[1])
      try {
        if (search.initialDate !== null) {
          let initialDate = new Date(
            search.initialDate.split('-')[0],
            search.initialDate.split('-')[1]
          )

          if (search.finalDate !== null) {
            let finalDate = new Date(
              search.finalDate.split('-')[0],
              search.finalDate.split('-')[1]
            )
            return issueDate >= initialDate && issueDate <= finalDate
          } else {
            return issueDate >= initialDate
          }
        }
        if (search.finalDate !== null) {
          let finalDate = new Date(
            search.finalDate.split('-')[0],
            search.finalDate.split('-')[1]
          )
          return finalDate >= issueDate
        }
      } catch (e) {
        console.log(e)
      }
    },
    loadLocalStorage() {
      let storageFilter = localStorage.getItem('invoiceStatus')
      if (storageFilter == undefined) {
        this.dateFilter.initialDate = this.statusData[1].issue_date
        this.dateFilter.finalDate = this.statusData[11].issue_date
        this.dateFilter.show = true
        localStorage.setItem('invoiceStatus', JSON.stringify(this.dateFilter))
      } else {
        storageFilter = JSON.parse(storageFilter)
        this.dateFilter.initialDate = storageFilter.initialDate
        this.dateFilter.finalDate = storageFilter.finalDate
        this.dateFilter.show = storageFilter.show
      }
    },
  },
}
</script>
