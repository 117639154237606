<template>
  <div>
    <app-section-loader :status="tableLoader" />

    <v-dialog v-model="export_all_list" max-width="350">
      <v-card class="sweeep-dialog">
        <v-card-title v-text="$t('message.invoice_list_all_export')" />
        <v-card-text>
          文字コードを指定して出力してください。
          <v-autocomplete
            v-model="export_all_list_selected_format"
            :items="export_all_list_format_list"
            label="文字コード"
            outlined
            filled
            dense
            class="more-dense mt-5"
            hide-details
            item-text="name"
            item-value="id"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click.native="
              export_all_list = false
              export_loading = false
            "
            v-text="$t('message.modal_cancel_btn')"
          />
          <v-btn
            :loading="export_loading"
            color="primary"
            @click="downloadCSV()"
          >
            {{ $t('message.invoice_table_export') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_export" max-width="350">
      <v-card class="sweeep-dialog">
        <v-card-title v-text="$t('message.invoice_list_export')" />

        <v-form ref="form_export">
          <v-card-text>
            {{ selected.length }}件の請求仕訳をダウンロードします。

            <base-alert v-if="format_list.length <= 0" color="red" class="mt-2">
              表示可能なフォーマット設定がありません。<br />
              （管理者設定メニューで表示設定を変更できます。）
            </base-alert>

            <v-autocomplete
              v-model="selected_format"
              :items="format_list"
              label="フォーマット"
              outlined
              filled
              dense
              class="more-dense mt-5"
              :rules="fileRules"
              item-text="name"
              item-value="id"
            />

            <v-select
              v-model="selected_encode_type"
              :items="encode_type_list"
              label="文字コード"
              outlined
              filled
              dense
              class="more-dense"
              hide-details
              :rules="fileRules"
            />

            <v-checkbox
              v-model="export_header"
              v-tooltip="'チェックをOffにするとヘッダ行を出力しません'"
              label="ヘッダを出力する"
              color="primary"
              hide-details
            />

            <v-checkbox
              v-model="isQuoteAll"
              v-tooltip="'チェックをOnにすると全ての項目をダブルコーテーション囲みます'"
              label="全てをダブルコーテーションで囲む"
              color="primary"
              hide-details
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              @click.native="
                dialog_export = false
                export_loading = false
              "
              v-text="$t('message.modal_cancel_btn')"
            />
            <v-btn
              v-if="!export_loading"
              color="primary"
              @click="exportInvoice"
              v-text="$t('message.invoice_table_export')"
            />
            <v-btn v-else loading color="primary" />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <bulk-edit-invoice-dialog
      ref="bulkEditInvoiceDialog"
      :definition="definition"
      :pay-types="payTypes"
      @submit="checkMultipleEdit($event)"
    />

    <base-dialog
      ref="dialogApprove"
      title="一括承認"
      :message="$t('message.approve_all_invoices_confirm')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.invoice_last_approve')"
      @submit="
        approveAll()
        $refs.dialogApprove.close()
      "
    />

    <freee-post-dialog
      v-if="showFreeeDialog"
      ref="freeePostDialog"
      :freee-separate-approval="freeeSeparateApproval"
      @close="showFreeeDialog = false"
      @posted:deal="$emit('change:invoice-detail')"
    />

    <base-dialog
      ref="dialogPayApprove"
      title="支払連携"
      message="選択した請求書を支払に連携しますか？"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="'連携する'"
      size="medium"
      @submit="
        payApproveAll()
        $refs.dialogPayApprove.close()
      "
    >
      <template #card-text>
        選択した請求書を支払に連携しますか？<br />
        <div v-show="alreadyPayApprovedIds.length > 0">
          以下の伝票No.は連携済のため、再連携されずスキップされます。<br />
          <span
            v-for="id in alreadyPayApprovedIds"
            :key="`already_pay_approve_${id}`"
          >
            <v-chip label x-small class="mt-2 mr-2"> ＃{{ id }} </v-chip>
          </span>
        </div>
      </template>
    </base-dialog>

    <base-dialog
      ref="dialogUnableToPayApprove"
      title="支払連携エラー"
      :cancel-text="$t('message.modal_close_btn')"
      hide-submit-btn
      size="medium"
    >
      <template #card-text>
        支払先情報の必須項目が入力されていない請求書が選択されています。<br />
        支払方法が振込の場合、支払先情報は必須です。<br />
        以下の伝票No.の支払先情報を入力してください。
        <div>
          <span
            v-for="id in unableToPayApproveIds"
            :key="`unable_to_pay_approve_${id}`"
          >
            <v-chip label x-small class="mt-2 mr-2"> ＃{{ id }} </v-chip>
          </span>
        </div>
      </template>
    </base-dialog>

    <base-dialog
      ref="dialogUnableBulkUpdate"
      title="確認"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.yes')"
      close-on-submit
      size="medium"
      @submit="multipleEditInvoice()"
    >
      <template #card-text>
        <p>{{ bulkupdateInfo.message }}</p>
        <div>
          <span
            v-for="id in bulkupdateInfo.approvedLocalIds"
            :key="`unable_to_pay_approve_${id}`"
          >
            <v-chip label x-small class="mt-2 mr-2"> ＃{{ id }} </v-chip>
          </span>
        </div>
      </template>
    </base-dialog>

    <input-delete-dialog
      ref="dialogDelete"
      :title="'請求書の削除'"
      :message="$t('message.delete_all_invoices_confirm')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :delete-text="$t('message.delete')"
      @submit="
        deleteAll()
        $refs.dialogDelete.close()
      "
    />

    <cannot-delete-dialog ref="cannotDelete" />

    <time-stamp-validation-dialog
      ref="timeStampValidation"
      :items="filteredValitedItems"
      :loading="timeStampValidationLoading"
      @submit="validateTimeStamp"
    />

    <v-toolbar flat>
      <v-menu nudge-bottom="45" transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              v-tooltip="'選択中のデータを一括で操作'"
              v-text="'一括操作'"
            />
          </div>
        </template>
        <v-list class="fs-12">
          <v-list-item
            v-if="checkPrivilege('invoice:update')"
            :disabled="searchForm.deletedStatusChoice == 2"
            class="border-bottom-1"
            @click="openMultipleEditInvoiceDialog"
          >
            <s-icon class="tw-mr-3" icon="feather-edit-2" />
            編集
          </v-list-item>

          <v-list-item
            v-if="checkPrivilege('invoice:approve5')"
            :disabled="searchForm.deletedStatusChoice == 2"
            class="border-bottom-1"
            @click="openApproveAllDialog"
          >
            <s-icon class="tw-mr-3" icon="feather-lock" />
            {{ $t('message.invoice_last_approve') }}
          </v-list-item>

          <v-list-item
            v-if="
              freeeSeparateApproval && checkPrivilege('invoice:approve_freee')
            "
            :disabled="searchForm.deletedStatusChoice == 2"
            class="border-bottom-1"
            @click="freeeApproveAll"
          >
            <v-avatar size="12" class="mr-3">
              <img src="/freee-logo.png" />
            </v-avatar>
            freeeへ同期
          </v-list-item>

          <v-list-item
            v-if="checkPrivilege('payment:approve4')"
            v-tooltip="{
              content: '選択中データを支払メニューに転送します',
              placement: 'right',
            }"
            :disabled="searchForm.deletedStatusChoice == 2"
            class="border-bottom-1"
            @click="openPayApproveDialog()"
          >
            <s-icon class="tw-mr-3" icon="ti-wallet" />
            支払連携
          </v-list-item>

          <v-list-item
            v-if="checkPrivilege('invoice:export_journal')"
            class="border-bottom-1"
            @click="openExportInvoiceDialog"
          >
            <s-icon class="tw-mr-3" icon="ti-export" />
            {{ $t('message.invoice_list_export') }}
          </v-list-item>

          <v-list-item
            v-if="checkPrivilege('invoice:export_table')"
            v-tooltip="{
              content: '表示中の一覧データをCSV形式でダウンロードします',
              placement: 'right',
            }"
            class="border-bottom-1"
            @click="export_all_list = true"
          >
            <s-icon class="tw-mr-3" icon="ti-export" />
            {{ $t('message.invoice_list_all_export') }}
          </v-list-item>

          <v-list-item
            v-if="checkPrivilege('invoice:delete')"
            :disabled="searchForm.deletedStatusChoice == 2"
            class="border-bottom-1"
            @click="onClickDelete()"
          >
            <s-icon class="tw-mr-3" icon="feather-trash-2" />
            {{ $t('message.delete') }}
          </v-list-item>

          <v-list-item
            v-if="isUsingTimeStamp"
            v-tooltip="{
              content: '選択した請求書のタイムスタンプの検証を行います',
              placement: 'right',
            }"
            class="border-bottom-1"
            @click="openValidateTimeStampDialog"
          >
            <v-icon x-small class="mr-3" v-text="'ti-stamp'" />
            タイムスタンプ検証
          </v-list-item>
        </v-list>
      </v-menu>

      <base-button
        icon="ti-layout-accordion-list"
        tooltip="仕訳の明細を展開します"
        :text="$t('message.invoice_list_show_all_journals')"
        class="ml-5"
        @click="onClickShowAllJournals(true)"
      />

      <base-button
        icon="ti-view-list-alt"
        tooltip="展開した仕訳明細を折り畳みます"
        :text="$t('message.invoice_list_hide_all_journals')"
        @click="onClickShowAllJournals(false)"
      />
    </v-toolbar>

    <div class="w-100 pa-0">
      <sweeep-invoice-table
        ref="table"
        :items="tableDataFiltered"
        :selected="selected"
        :items-per-page="itemsPerPage"
        :display-setting="displaySetting"
        :definition="definition"
        :sort-func="sortTableData"
        :sort-params="sortParams"
        :approve-level="$store.getters.approveLevel"
        :approve-settings="approveSettings"
        :pay-confirm-settings="payConfirmSettings"
        item-tips="詳細ビューで表示"
        @click:item="switchModeDetail"
        @change:items-per-page="onChangeItemsPerPage"
        @click:updateNote="onClickUpdateNote"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import api from 'Api'
import Encoding from 'encoding-japanese'
import Axios from 'axios'
import axios from 'axios'
import { mapGetters } from 'vuex'
import Snackbar from 'Helpers/snackbar/index'
import { getNameCode, checkEqualNameCodeId } from 'Helpers/invoice'
import { checkInclude, consoleLog } from 'Helpers/helpers'
import { createNotification } from 'Helpers/helpers'
import { downloadInvoices } from 'Helpers/table/download'
import { sliceText, onChangeJournal } from 'Helpers/journal'
import { filterTableByText } from 'Helpers/table'

import SweeepInvoiceTable from 'Components/Table/SweeepInvoiceTable'
import DatePickInput from 'Components/Input/DatePickInput'
import JournalIterators from 'Components/Journal/JournalIterators.vue'
import JournalFormHeader from 'Components/Journal/JournalFormHeader.vue'
import JournalTextField from 'Components/Journal/JournalTextField.vue'

// Dialogs
import FreeePostDialog from 'Views/invoice/components/Dialog/FreeePostDialog.vue'
import CannotDeleteDialog from 'Views/invoice/components/Dialog/CannotDeleteDialog.vue'
import BulkEditInvoiceDialog from 'Views/invoice/components/Dialog/BulkEditInvoiceDialog.vue'

import TimeStampValidationDialog from 'Views/invoice/components/Dialog/TimeStampValidationDialog'

import { exportWarnDialogBuilder } from 'Helpers/confirm/factory'
import bulkEditHelper from 'Helpers/invoice/bulkUpdate'

import { isJournalLocked } from "@/services/invoice/journalLock"

export default {
  components: {
    SweeepInvoiceTable,
    DatePickInput,
    CannotDeleteDialog,
    JournalIterators,
    JournalFormHeader,
    JournalTextField,
    FreeePostDialog,
    TimeStampValidationDialog,
    BulkEditInvoiceDialog,
  },
  props: {
    definition: Object,
    tableLoader: null,
    sortParams: Object,
    tableDataFiltered: Array,
    getTableData: Function,
    exchangeTableData: Function,
    removeInvoice: Function,
    sortTableData: Function,
    sortTableDataSameAsPrevious: Function,
    setPreviousIdList: Function,
    allSelected: null,
    switchModeDetail: Function,
    searchForm: Object,
    selected_from_parent: null,
    getSubjectList: Function,
    invoiceSidebarRef: null,
    approveSettings: Object,
    payTypes: Array,
    freeeSeparateApproval: Boolean,
    settingForeignCurrency: {
      type: Boolean,
      required: true,
    },
    payConfirmSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      encode_type_list: ['Shift-JIS', 'utf-8'],
      selected_encode_type: 'Shift-JIS',
      export_header: true,
      export_loading: false,
      headerLoader: true,
      isQuoteAll: false,

      itemsPerPage: 20,

      input_DELETE: '',
      storageCol: [],
      exports_count: [],
      dateRules: [(v) => !!v || '※この項目は必須です'],
      fileRules: [(v) => !!v || '※この項目は必須です'],
      menu: '',
      menu2: '',
      search: '',
      file_info: '',
      hovered_invoice_id: '',
      month: '',
      year: '',
      inputRules: [(v) => !!v || '※この項目は必須です'],
      dialog_export: false,
      dialogBulkEditing: false,
      multiple_date: '',

      journalItems: {},

      fixedHeaderCount: 8,
      saved_column_settings: [],
      exportAll: false,
      total_columns: 27,
      format_list: [],
      selected_format: '',
      exportFileName: '',

      export_all_list: false,
      export_all_list_selected_format: 'Shift-JIS',
      export_all_list_format_list: ['Shift-JIS', 'utf-8'],
      items: [],
      scrollTimer: null,
      page: 1,
      showFreeeDialog: false,
      unableToPayApproveIds: [],
      alreadyPayApprovedIds: [],
      selectedInvoiceValidations: {},
      timeStampValidationLoading: false,
      bulkupdateInfo: {
        message: '',
        approvedLocalIds: [],
        updatableInvoiceIds: [],
      },
      editJounralForm: {},
    }
  },
  updated() {
    consoleLog('updateRender', 'InvoiceTable')
  },
  created() {
    this.getFormatList()
    this.setItemsPerPage()
  },
  beforeDestroy: function () {
    clearInterval(this.scrollTimer)
  },
  computed: {
    ...mapGetters([
      'displaySetting',
      'showJournalTextArea',
      'invoicePrivilege',
      'settingJournalLockMode'
    ]),
    selected: function () {
      return this.tableDataFiltered.filter((v) => v.selected)
    },
    filterDateSettings: function () {
      try {
        if (this.searchForm.filterDateTypes === 0) {
          return {
            dateFrom: 'reading_date_from',
            dateTo: 'reading_date_to',
            itemDate: 'reading_date',
          }
        } else {
          return {
            dateFrom: 'date',
            dateTo: 'date_to',
            itemDate: 'issue_date',
          }
        }
      } catch (e) {
        console.log(e)
        return {}
      }
    },
    filteredValitedItems() {
      return Object.values(this.selectedInvoiceValidations)
    },
    isUsingTimeStamp() {
      return this.$store.getters.settingTimestamp
    },
  },
  watch: {
    tableLoader: function (val) {
      if (val === false) {
        this.resetPage()
      }
    },
  },
  methods: {
    downloadCSV() {
      try {
        this.export_loading = true
        this.setPreviousIdList()
        const selectedIdList = this.getSelectedID()
        const exportOptions = {
          settingForeignCurrency: this.settingForeignCurrency,
        }
        this.getTableData().then((response) => {
          if (response) {
            this.sortTableDataSameAsPrevious()
            this.sortTableData(this.sortParams.value, this.sortParams.mode)
            this.checkIdFromList(selectedIdList)
            this.$nextTick(function () {
              downloadInvoices(
                this.tableDataFiltered,
                this.export_all_list_selected_format,
                exportOptions
              ).then((result) => {
                this.export_loading = false
                if (result) {
                  this.export_all_list = false
                  Snackbar.success('成功しました。')
                } else {
                  Snackbar.success('エラーが発生しました。')
                }
              })
            })
          } else {
            this.export_loading = false
            Snackbar.success('エラーが発生しました。')
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    openMultipleEditInvoiceDialog() {
      if (this.selected.length <= 0) {
        Snackbar.error('データを選択してください。')
        return
      }
      this.$refs.bulkEditInvoiceDialog.open()
    },
    checkMultipleEdit(form) {
      this.editJounralForm = form
      const {
        updatableInvoices,
        unupdatableInvoices,
        updateJournal,
        updatePayment,
        noFieldChanged,
      } = bulkEditHelper.verify(form, this.selected, {
        isJournalUpdatable: (invoice) => {
          return !isJournalLocked(invoice, this.invoicePrivilege, this.settingJournalLockMode)
        }
      })
      if (noFieldChanged) {
        Snackbar.error(
          '更新する項目が入力されていません。再度ご確認をお願いします。'
        )
        return
      }
      let errorSuffix = ''
      if (updateJournal && updatePayment) {
        errorSuffix += '仕訳承認・支払連携済'
      } else if (updateJournal || updatePayment) {
        errorSuffix += updatePayment ? '支払連携済' : '仕訳承認済'
      }
      if (updatableInvoices.length === 0) {
        Snackbar.error(
          `${errorSuffix}のデータは変更できません。\n変更可能なデータを選択してください。`
        )
        return
      }
      this.$set(
        this.bulkupdateInfo,
        'updatableInvoiceIds',
        updatableInvoices.map((invoice) => invoice.id)
      )
      if (updatableInvoices.length !== this.selected.length) {
        this.bulkupdateInfo.message =
          errorSuffix +
          'のデータが含まれています。以下の請求書は更新できません。\n以下のデータを除いて一括編集を行いますか?'
        this.$set(
          this.bulkupdateInfo,
          'approvedLocalIds',
          unupdatableInvoices.map((invoice) => invoice.image_local_id)
        )
        this.$refs.dialogUnableBulkUpdate.open()
      } else {
        this.multipleEditInvoice()
      }
    },
    multipleEditInvoice() {
      let journal_form = this.editJounralForm
      for (let key in journal_form) {
        journal_form[key] = journal_form[key] === null ? '' : journal_form[key]
      }
      const data = this.bulkupdateInfo.updatableInvoiceIds

      this.$store
        .dispatch('multipleEditInvoice', {
          data,
          journal_form,
        })
        .then((response) => {
          if (response.data.status == 'success') {
            Snackbar.success(this.$t('message.selected_invoices_edited'))
            const idList = this.getSelectedID()
            this.getTableData().then(() => {
              this.checkIdFromList(idList)
              this.$emit('change:invoice-detail')
            })
            this.$refs.bulkEditInvoiceDialog.close()
          }
        })
    },
    getFormatList() {
      const start = new Date()
      try {
        let url = this.$store.getters.apiExportFormatListUrl
        const auth_token = this.$store.getters.getAuthToken
        url += '?organization_id=' + this.$store.getters.getOrganizationID
        axios
          .get(url, {
            headers: { Authorization: auth_token },
            params: { exclude_hidden: true },
          })
          .then((response) => {
            try {
              this.format_list = response.data.data
              this.selected_format = this.format_list[0].id
            } catch (error) {
              Snackbar.error(error)
            }
            consoleLog('API', 'getFormatList', start)
          })
      } catch (error) {
        Snackbar.error(error)
      }
    },
    openApproveAllDialog() {
      if (this.selected.length <= 0) {
        Snackbar.error('データを選択してください。')
        return
      }
      this.$refs.dialogApprove.open()
    },
    async approveAll() {
      const getPayload = (data, approve_user5, date_approve5) => {
        const payload = data.map((v) => {
          let imageSummaryId = v.id
          let attributes = { approve_user5, date_approve5 }
          return { imageSummaryId, attributes }
        })
        return payload
      }
      try {
        const invoiceNotApproved = this.selected.filter(
          (invoice) => !invoice.date_approve5
        )
        let errorMessage = ''
        if (this.selected.length === 0) {
          errorMessage = 'データを選択してください。'
        } else if (invoiceNotApproved.length === 0) {
          errorMessage =
            '承認済みのデータを再承認することはできません。再度ご確認ください。'
        }
        if (errorMessage) {
          return Snackbar.error(errorMessage)
        }
        if (invoiceNotApproved.length !== this.selected.length) {
          const yes = await this.$confirm({
            message:
              '承認済みのデータが含まれます。未承認のデータのみ承認しますか？',
          })
          if (!yes) {
            return
          }
        }
        const auth_token = this.$store.getters.getAuthToken
        const apiUrl = this.$store.getters.apiInvoiceMultiApproveFunction
        this.$store
          .dispatch('multipleApproveInvoice', {
            apiUrl,
            auth_token,
            data: invoiceNotApproved,
          })
          .then((response) => {
            const approve_user5 = response.data.approve_user
            const date_approve5 = response.data.date
            const event = getPayload(
              invoiceNotApproved,
              approve_user5,
              date_approve5
            )

            this.$emit('change:table-values', event)

            if (response.data.status === 'success') {
              Snackbar.success(this.$t('message.selected_invoices_approved'))
              const freeeTokenExists = this.$store.getters.freeeTokenExists
              if (!this.freeeSeparateApproval && freeeTokenExists) {
                this.freeeApproveAll()
              }
            }
          })
          .catch((error) => {
            const msgs = {
              no_privilege: '権限のないデータが選択されています。',
            }
            const msg = msgs[error]
              ? msgs[error]
              : '不明なエラーが発生しました。'
            Snackbar.error(msg)
          })
      } catch (e) {
        console.log(e)
      }
    },
    async freeeApproveAll() {
      const invoiceApproved = this.selected.filter(
        (invoice) => invoice.date_approve5
      )
      const hasPrivilege = this.freeeSeparateApproval
        ? invoiceApproved.every((invoice) => invoice.privileges.approve_freee)
        : invoiceApproved.every((invoice) => invoice.privileges.approve5)

      let errorMessage = ''
      if (invoiceApproved.length === 0) {
        errorMessage =
          '最終承認済みのデータが選択されていません。最終承認済みのデータのみ同期できます。'
      } else if (!hasPrivilege) {
        errorMessage = '権限のないデータが選択されています。'
      }
      if (errorMessage) {
        return Snackbar.error(errorMessage)
      }
      if (invoiceApproved.length !== this.selected.length) {
        const yes = await this.$confirm({
          message:
            '最終承認前のデータが含まれます。承認済みのデータのみ同期しますか？',
        })
        if (!yes) {
          return
        }
      }
      this.showFreeeDialog = true
      const items = invoiceApproved.map((instance) => {
        const id = instance.id
        const image_local_id = instance.image_local_id
        return { id, image_local_id }
      })
      this.$nextTick(() => {
        this.$refs.freeePostDialog.open({
          items,
          multiple: true,
        })
      })
    },
    payApproveAll() {
      try {
        const auth_token = this.$store.getters.getAuthToken
        const url = this.$store.getters.apiInvoicePayMultiApproveFunction
        const data = {
          id_list: this.getSelectedID(),
          user_organization_id: this.$store.getters.getUserOrganizationID,
          user_id: this.$store.getters.getUserID,
        }
        const params = {
          headers: { Authorization: auth_token },
        }
        axios.post(url, data, params).then((response) => {
          if (response.data.status == 'success') {
            Snackbar.success('連携しました。')
            const pay_approve_user = response.data.pay_approve_user
            const date_pay_approve = response.data.date
            const update_id_list = response.data.update_id_list
            const attributes = { pay_approve_user, date_pay_approve }
            const payload = update_id_list.map((imageSummaryId) => {
              return { imageSummaryId, attributes }
            })
            this.$emit('change:table-values', payload)
          } else {
            const error = response.data.error
            const msgs = {
              no_privilege: '権限のないデータが選択されています。',
            }
            const msg = msgs[error]
              ? msgs[error]
              : '不明なエラーが発生しました。'
            Snackbar.error(msg)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    openPayApproveDialog() {
      try {
        this.unableToPayApproveIds = []
        this.alreadyPayApprovedIds = []
        if (this.selected.length <= 0) {
          Snackbar.error('データを選択してください。')
          return
        }
        const unableList = this.checkDataUnableToPayApprove()
        const alreadyApprovedList = this.checkDataApproved()
        if (alreadyApprovedList.length == this.selected.length) {
          Snackbar.error('支払未連携のデータを選択してください。')
          return
        }

        if (unableList.length > 0) {
          this.unableToPayApproveIds = unableList.map((item) => {
            return item.image_local_id
          })
          this.$refs.dialogUnableToPayApprove.open()
          return
        }
        if (alreadyApprovedList.length > 0) {
          this.alreadyPayApprovedIds = alreadyApprovedList.map((item) => {
            return item.image_local_id
          })
        } else {
          this.alreadyPayApprovedIds = []
        }
        this.$refs.dialogPayApprove.open()
      } catch (e) {
        console.log(e)
      }
    },
    openValidateTimeStampDialog() {
      if (this.selected.length == 0) {
        Snackbar.error('データを選択してください')
        return
      }

      try {
        this.$set(this, 'selectedInvoiceValidations', {})
        for (let invoice of this.selected) {
          let data = {
            localId: invoice.image_local_id,
            companyName: invoice.company_name,
          }
          this.$set(this.selectedInvoiceValidations, String(invoice.id), data)
        }
        this.$refs.timeStampValidation.open()
      } catch (e) {
        console.log(e)
      }
    },
    validateTimeStamp() {
      try {
        this.timeStampValidationLoading = true

        const auth_token = this.$store.getters.getAuthToken
        const url = this.$store.getters.apiTimeStampValidationsUrl
        const params = {
          image_summary_ids: JSON.stringify(
            Object.keys(this.selectedInvoiceValidations)
          ),
        }

        axios
          .get(url, {
            headers: { Authorization: auth_token },
            params: params,
          })
          .then((response) => {
            if (response.data.status == 'success') {
              for (let [key, value] of Object.entries(response.data.results)) {
                this.$set(
                  this.selectedInvoiceValidations[key],
                  'isValid',
                  value.is_valid
                )
                this.$set(
                  this.selectedInvoiceValidations[key],
                  'invalidDetail',
                  value.invalid_detail
                )
              }
              this.timeStampValidationLoading = false
            } else {
              this.timeStampValidationLoading = false
              Snackbar.error(response.data.message)
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    checkDataUnableToPayApprove() {
      try {
        //pay_typeが1かつ必須項目未入力のデータを確認
        const items = this.selected
        const checkEmptyOrBlank = function (obj) {
          return (!obj && obj !== 0) || obj == ''
        }
        return items.filter(
          (item) =>
            item.pay_type === 1 &&
            (!item.bank_data ||
              checkEmptyOrBlank(item.bank_data.bank_code) ||
              checkEmptyOrBlank(item.bank_data.branch_code) ||
              checkEmptyOrBlank(item.bank_data.bank_account_type) ||
              checkEmptyOrBlank(item.bank_data.bank_account_name) ||
              checkEmptyOrBlank(item.bank_data.bank_account_number))
        )
      } catch (e) {
        console.log(e)
      }
    },
    checkDataApproved() {
      try {
        //支払連携済のデータを確認
        const items = this.selected
        return items.filter(
          (item) => item.pay_approve_user && item.date_pay_approve
        )
      } catch (e) {
        console.log(e)
      }
    },
    getSelectedID() {
      let idList = []
      for (let x = 0; x < this.selected.length; x++) {
        idList.push(this.selected[x].id)
      }
      return idList
    },
    checkIdFromList(idList) {
      try {
        idList.forEach((id) => {
          let item = this.tableDataFiltered.find((x) => x.id == id)
          if (item) {
            this.$set(item, 'selected', true)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    createExportFileName(formatId) {
      const format = this.format_list.find((obj) => obj.id === formatId)
      const formatName = format ? format.name : 'export'
      return formatName + '_' + this.formatDate(new Date()) + '.csv'
    },
    formatDate(date) {
      // return YYYYMMDD-hhmmss
      return (
        date.getFullYear() +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        ('0' + date.getDate()).slice(-2) +
        '-' +
        ('0' + date.getHours()).slice(-2) +
        ('0' + date.getMinutes()).slice(-2) +
        ('0' + date.getSeconds()).slice(-2)
      )
    },
    customFilter(items, filters, filter, headers, callBackSettings = {}) {
      try {
        const cf = new this.$MultiFilters(items, filters, filter, headers)
        let t = this
        cf.registerFilter('search_bar', (search_bar, items) =>
          filterTableByText(search_bar, items)
        )

        cf.registerFilter('company_name', function (company_name, items) {
          if (company_name.trim() === '') {
            return items
          }
          let invoice_items = items.filter((item) => {
            if (!item.company_name) {
              return false
            } else {
              return checkInclude(item.company_name, company_name.toLowerCase())
            }
          }, company_name)
          return invoice_items
        })

        cf.registerFilter('department', function (department, items) {
          if (!department || !department.name) {
            return items
          }
          const valueInForm = getNameCode(department)

          let filteredItems = items.filter((item) => {
            if (!item.department || !item.department.name) {
              return valueInForm === '未指定 zn8qm40qrx'
            } else {
              return checkEqualNameCodeId(department, item.department)
            }
          })
          return filteredItems
        })

        cf.registerFilter('upload_source', function (upload_source, items) {
          if (upload_source.trim() === '') {
            return items
          }
          let invoice_items = items.filter((item) => {
            if (!item.image__upload_source) {
              return false
            } else {
              return checkInclude(
                item.image__upload_source,
                upload_source.toLowerCase()
              )
            }
          }, upload_source)
          return invoice_items
        })

        cf.registerFilter('project_local_bank_code', function (form, items) {
          if (!form) {
            form = ''
            return items
          } else {
            if (form.trim() === '') {
              return items
            }
          }
          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.credit_project && !journal.debit_project) {
                continue
              }
            }
          }, form)
          return items_fil
        })

        cf.registerFilter(
          'debit_project_local_bank_code',
          function (form, items) {
            if (!form) {
              form = ''
              return items
            } else {
              if (form.trim() === '') {
                return items
              }
            }
            let items_fil = items.filter((item) => {
              for (let journal of item.journal) {
                if (!journal.debit_project) {
                  continue
                }
              }
            }, form)

            return items_fil
          }
        )

        cf.registerFilter(
          'credit_project_local_bank_code',
          function (form, items) {
            if (!form) {
              form = ''
              return items
            } else {
              if (form.trim() === '') {
                return items
              }
            }
            let items_fil = items.filter((item) => {
              for (let journal of item.journal) {
                if (!journal.credit_project) {
                  continue
                }
              }
            }, form)

            return items_fil
          }
        )

        cf.registerFilter('description', function (text, items) {
          if (text.trim() === '') {
            return items
          }
          try {
            let invoice_items = items.filter((item) => {
              for (let journal of item.journal) {
                if (!journal.description) {
                  continue
                } else if (
                  checkInclude(journal.description, text.toLowerCase())
                ) {
                  return true
                }
              }
            }, text)
            return invoice_items
          } catch (e) {
            console.log(e)
          }
        })

        cf.registerFilter('freeText1', function (text, items) {
          if (text.trim() === '') {
            return items
          }
          try {
            let invoice_items = items.filter((item) => {
              for (let journal of item.journal) {
                if (!journal.free_text_1) {
                  continue
                } else if (
                  checkInclude(journal.free_text_1, text.toLowerCase())
                ) {
                  return true
                }
              }
            }, text)
            return invoice_items
          } catch (e) {
            console.log(e)
          }
        })

        cf.registerFilter('freeText2', function (text, items) {
          if (text.trim() === '') {
            return items
          }
          try {
            let invoice_items = items.filter((item) => {
              for (let journal of item.journal) {
                if (!journal.free_text_2) {
                  continue
                } else if (
                  checkInclude(journal.free_text_2, text.toLowerCase())
                ) {
                  return true
                }
              }
            }, text)
            return invoice_items
          } catch (e) {
            console.log(e)
          }
        })

        cf.registerFilter('approve_status', function (approve_status, items) {
          // 0: 全て 1:未承認、承認中 2: 承認済
          if (approve_status === 0) {
            return items
          } else if (![1, 2].includes(approve_status)) {
            console.error('approve_status is unexpected type')
            return items
          }
          if (approve_status == 1) {
            return items.filter((item) => item.approve_user5.id)
          } else if (approve_status == 2) {
            return items.filter((item) => !item.approve_user5.id)
          }
        })

        cf.registerFilter(
          'pay_approve_status',
          function (pay_approve_status, items) {
            // 0: 全て 1:未承認、承認中 2: 承認済
            if (pay_approve_status === 0) {
              return items
            } else if (![1, 2].includes(pay_approve_status)) {
              console.error('pay_approve_status is unexpected type')
              return items
            }
            if (pay_approve_status == 1) {
              return items.filter((item) => item.pay_approve_user.id)
            } else if (pay_approve_status == 2) {
              return items.filter((item) => !item.pay_approve_user.id)
            }
          }
        )

        cf.registerFilter('approve1', function (approve, items) {
          if (!approve) {
            approve = ''
            return items
          } else {
            if (approve.trim() === '') {
              return items
            }
          }
          let invoice_items = items.filter((item) => {
            if (approve == '承認済') {
              if (item.approve_user1.id) {
                return true
              }
            } else if (approve == '未承認') {
              if (!item.approve_user1.id) {
                return true
              }
            } else {
              return true
            }
          }, approve)

          return invoice_items
        })

        cf.registerFilter('approve2', function (approve, items) {
          if (!approve) {
            approve = ''
            return items
          } else {
            if (approve.trim() === '') {
              return items
            }
          }
          let invoice_items = items.filter((item) => {
            if (approve == '承認済') {
              if (item.approve_user2.id) {
                return true
              }
            } else if (approve == '未承認') {
              if (!item.approve_user2.id) {
                return true
              }
            } else {
              return true
            }
          }, approve)

          return invoice_items
        })
        cf.registerFilter('approve3', function (approve, items) {
          if (!approve) {
            approve = ''
            return items
          } else {
            if (approve.trim() === '') {
              return items
            }
          }
          let invoice_items = items.filter((item) => {
            if (approve == '承認済') {
              if (item.approve_user3.id) {
                return true
              }
            } else if (approve == '未承認') {
              if (!item.approve_user3.id) {
                return true
              }
            } else {
              return true
            }
          }, approve)

          return invoice_items
        })

        cf.registerFilter('approve4', function (approve, items) {
          if (!approve) {
            approve = ''
            return items
          } else {
            if (approve.trim() === '') {
              return items
            }
          }
          let invoice_items = items.filter((item) => {
            if (approve == '承認済') {
              if (item.approve_user4.id) {
                return true
              }
            } else if (approve == '未承認') {
              if (!item.approve_user4.id) {
                return true
              }
            } else {
              return true
            }
          }, approve)

          return invoice_items
        })

        cf.registerFilter('approve5', function (approve, items) {
          if (!approve) {
            approve = ''
            return items
          } else {
            if (approve.trim() === '') {
              return items
            }
          }
          let invoice_items = items.filter((item) => {
            if (approve == '承認済') {
              if (item.approve_user5.id) {
                return true
              }
            } else if (approve == '未承認') {
              if (!item.approve_user5.id) {
                return true
              }
            } else {
              return true
            }
          }, approve)
          return invoice_items
        })

        cf.registerFilter('debit_account_title', function (obj, items) {
          if (!obj) {
            return items
          }
          if (callBackSettings.debitAccountTitle) {
            return items.filter((item) => {
              return item.journal.some(
                (journal) =>
                  !(
                    journal.debit_account_title &&
                    journal.debit_account_title.id
                  )
              )
            })
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.debit_account_title) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.debit_account_title)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('debit_sub_account', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.debit_sub_account) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.debit_sub_account)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('debit_department', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.debit_department) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.debit_department)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('debit_vendor', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.debit_vendor) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.debit_vendor)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('debit_project', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.debit_project) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.debit_project)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('debit_segment', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.debit_segment) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.debit_segment)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('debit_walletable', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.debit_walletable) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.debit_walletable)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('debit_item', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.debit_item) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.debit_item)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('debit_tag', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.debit_tag) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.debit_tag)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('debit_tax_class', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.debit_tax_class) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.debit_tax_class)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('credit_account_title', function (obj, items) {
          if (!obj) {
            return items
          }
          if (callBackSettings.creditAccountTitle) {
            return items.filter((item) => {
              return item.journal.some(
                (journal) =>
                  !(
                    journal.credit_account_title &&
                    journal.credit_account_title.id
                  )
              )
            })
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.credit_account_title) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.credit_account_title)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('credit_sub_account', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.credit_sub_account) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.credit_sub_account)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('credit_department', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.credit_department) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.credit_department)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('credit_vendor', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.credit_vendor) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.credit_vendor)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('credit_project', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.credit_project) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.credit_project)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('credit_segment', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.credit_segment) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.credit_segment)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('credit_walletable', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.credit_walletable) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.credit_walletable)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('credit_item', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.credit_item) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.credit_item)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('credit_tag', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.credit_tag) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.credit_tag)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('credit_tax_class', function (obj, items) {
          if (!obj) {
            return items
          }

          let items_fil = items.filter((item) => {
            for (let journal of item.journal) {
              if (!journal.credit_tax_class) {
                continue
              }

              if (checkEqualNameCodeId(obj, journal.credit_tax_class)) {
                return true
              }
            }
          }, name)

          return items_fil
        })

        cf.registerFilter('reading_date_to', function (date, items) {
          if (t.searchForm.filterDateType === 1) {
            return items
          }
          let parsed_date = new Date(date)
          if (!date) {
            date = ''
            return items
          } else {
            if (date.trim() === '') {
              return items
            }
          }
          let invoice_items = items.filter((item) => {
            let parsed_date_2 = new Date(item.reading_date)
            if (parsed_date_2 <= parsed_date) {
              return item
            }
          }, parsed_date)

          return invoice_items
        })

        cf.registerFilter('reading_date_from', function (date, items) {
          if (t.searchForm.filterDateType === 1) {
            return items
          }
          let parsed_date = new Date(date)
          if (!date) {
            date = ''
            return items
          } else {
            if (date.trim() === '') {
              return items
            }
          }
          let invoice_items = items.filter((item) => {
            let parsed_date_2 = new Date(item.reading_date)
            if (parsed_date_2 >= parsed_date) {
              return item
            }
          }, parsed_date)

          return invoice_items
        })

        cf.registerFilter('date', function (date, items) {
          if (t.searchForm.filterDateType === 0) {
            return items
          }
          let parsed_date = new Date(date)
          if (!date) {
            if (callBackSettings.issueDate) {
              return items.filter((item) => !item.issue_date)
            } else {
              return items
            }
          }
          let invoice_items = items.filter((item) => {
            let parsed_date_2 = new Date(item.issue_date)
            if (parsed_date_2 >= parsed_date) {
              return item
            }
          }, parsed_date)

          return invoice_items
        })

        cf.registerFilter('date_to', function (date, items) {
          if (t.searchForm.filterDateType === 0) {
            return items
          }
          let parsed_date = new Date(date)
          if (!date) {
            date = ''
            return items
          } else {
            if (date.trim() === '') {
              return items
            }
          }
          let invoice_items = items.filter((item) => {
            let parsed_date_2 = new Date(item.issue_date)
            if (parsed_date_2 <= parsed_date) {
              return item
            }
          }, parsed_date)

          return invoice_items
        })

        cf.registerFilter('pay_date_from', function (date, items) {
          if (t.searchForm.filterDateType === 2) {
            return items
          }
          let parsed_date = new Date(date)
          if (!date) {
            date = ''
            return items
          } else {
            if (date.trim() === '') {
              return items
            }
          }
          let invoice_items = items.filter((item) => {
            let parsed_date_2 = new Date(item.pay_date)
            if (parsed_date_2 >= parsed_date) {
              return item
            }
          }, parsed_date)

          return invoice_items
        })

        cf.registerFilter('pay_date_to', function (date, items) {
          if (t.searchForm.filterDateType === 2) {
            return items
          }
          let parsed_date = new Date(date)
          if (!date) {
            date = ''
            return items
          } else {
            if (date.trim() === '') {
              return items
            }
          }
          let invoice_items = items.filter((item) => {
            let parsed_date_2 = new Date(item.pay_date)
            if (parsed_date_2 <= parsed_date) {
              return item
            }
          }, parsed_date)

          return invoice_items
        })

        cf.registerFilter('amount_1', function (amount_1, items) {
          if (!amount_1 || amount_1 == 0) {
            amount_1 = 0
            return items
          }

          let invoice_items = items.filter((item) => {
            let x = (amount_1 = parseFloat(amount_1))
            let parsedValue = parseFloat(item.debit_amount_total)
            if (parsedValue >= x) {
              return item
            }
          }, amount_1)

          return invoice_items
        })

        cf.registerFilter('amount_2', function (amount_2, items) {
          if (!amount_2 || amount_2 == 0) {
            amount_2 = 0
            return items
          }

          let invoice_items = items.filter((item) => {
            let x = (amount_2 = parseFloat(amount_2))
            let parsedValue = parseFloat(item.debit_amount_total)
            if (parsedValue <= x) {
              return item
            }
          }, amount_2)
          return invoice_items
        })

        cf.registerFilter('scoreChoice', function (scoreChoice, items) {
          if ([1, 2, 3].indexOf(scoreChoice) < 0) {
            return items
          }

          let filterdItems = []
          if (scoreChoice == 1) {
            filterdItems = items.filter((item) => {
              if (item.score_max_amount >= 70) {
                return item
              }
            })
          } else if (scoreChoice == 2) {
            filterdItems = items.filter((item) => {
              if (item.score_max_amount < 70 && item.score_max_amount >= 50) {
                return item
              }
            })
          } else {
            filterdItems = items.filter((item) => {
              if (item.score_max_amount < 50) {
                return item
              }
            })
          }
          return filterdItems
        })

        cf.registerFilter(
          'transactionChoice',
          function (transactionChoice, items) {
            if ([1, 2].indexOf(transactionChoice) < 0) {
              return items
            }

            let filterdItems = []
            if (transactionChoice == 1) {
              filterdItems = items.filter((item) => {
                if (!item.past_transaction) {
                  return item
                }
              })
            } else {
              filterdItems = items.filter((item) => {
                if (item.past_transaction) {
                  return item
                }
              })
            }
            return filterdItems
          }
        )

        cf.registerFilter(
          'deletedStatusChoice',
          function (deletedStatusChoice, items) {
            if ([1, 2, 3].indexOf(deletedStatusChoice) < 0) {
              return items
            }

            if (deletedStatusChoice == 1) {
              return items.filter((item) => !item.is_deleted)
            } else if (deletedStatusChoice == 2) {
              return items.filter((item) => item.is_deleted)
            }
            return items
          }
        )

        cf.registerFilter(
          'readStatusChoice',
          function (readStatusChoice, items) {
            // 0: 全て 1:未読 2: 既読
            if (readStatusChoice === 0) {
              return items
            } else if (![1, 2].includes(readStatusChoice)) {
              console.error('readStatus is unexpected type')
              return items
            }
            if (readStatusChoice == 1) {
              return items.filter((item) => !item.date_viewed)
            } else if (readStatusChoice == 2) {
              return items.filter((item) => item.date_viewed)
            }
          }
        )

        cf.registerFilter('id_1', function (id_1, items) {
          if (!id_1 || id_1 == 0) {
            id_1 = 0
            return items
          }

          let invoice_items = items.filter((item) => {
            let x = (id_1 = parseFloat(id_1))
            let parsedValue = parseFloat(item.image_local_id)
            if (parsedValue >= x) {
              return item
            }
          }, id_1)
          return invoice_items
        })

        cf.registerFilter('id_2', function (id_2, items) {
          if (!id_2 || id_2 == 0) {
            id_2 = 0
            return items
          }

          let invoice_items = items.filter((item) => {
            let x = (id_2 = parseFloat(id_2))
            let parsedValue = parseFloat(item.image_local_id)
            if (parsedValue <= x) {
              return item
            }
          }, id_2)
          return invoice_items
        })

        cf.registerFilter('upload_user', function (id, items) {
          if (!id && id !== 0) {
            return items
          }
          let items_fil = items.filter((item) => {
            if (item.upload_user.id) {
              return item.upload_user.id == id
            } else {
              return false
            }
          }, id)

          return items_fil
        })

        cf.registerFilter('update_user', function (id, items) {
          if (!id && id !== 0) {
            return items
          }
          let items_fil = items.filter((item) => {
            if (item.update_user.id) {
              return item.update_user.id == id
            } else {
              return false
            }
          }, id)

          return items_fil
        })

        cf.registerFilter('approve_user', function (id, items) {
          if (!id && id !== 0) {
            return items
          }
          let items_fil = items.filter((item) => {
            if (item.approve_user1.id) {
              return item.approve_user1.id == id
            } else if (item.approve_user2.id) {
              return item.approve_user2.id == id
            } else if (item.approve_user3.id) {
              return item.approve_user3.id == id
            } else if (item.approve_user4.id) {
              return item.approve_user4.id == id
            } else if (item.approve_user5.id) {
              return item.approve_user5.id == id
            } else {
              return false
            }
          }, id)

          return items_fil
        })

        cf.registerFilter('export_user', function (id, items) {
          if (!id && id !== 0) {
            return items
          }
          let items_fil = items.filter((item) => {
            if (item.export_user.id) {
              return item.export_user.id == id
            } else {
              return false
            }
          }, id)

          return items_fil
        })

        cf.registerFilter('pay_export_user', function (id, items) {
          if (!id && id !== 0) {
            return items
          }
          let items_fil = items.filter((item) => {
            if (item.pay_export_user.id) {
              return item.pay_export_user.id == id
            } else {
              return false
            }
          }, id)

          return items_fil
        })

        cf.registerFilter('pay_approve_user', function (id, items) {
          if (!id && id !== 0) {
            return items
          }
          let items_fil = items.filter((item) => {
            if (item.pay_approve_user.id) {
              return item.pay_approve_user.id == id
            } else {
              return false
            }
          }, id)

          return items_fil
        })

        cf.registerFilter('paid_user', function (id, items) {
          if (!id && id !== 0) {
            return items
          }
          let items_fil = items.filter((item) => {
            if (item.paid_user.id) {
              return item.paid_user.id == id
            } else {
              return false
            }
          }, id)

          return items_fil
        })

        cf.registerFilter('upload_type', function (id, items) {
          if (!id) {
            return items
          }
          let items_fil = items.filter((item) => {
            if (item.upload_type_id) {
              return item.upload_type_id == id
            } else {
              return false
            }
          }, id)

          return items_fil
        })

        cf.registerFilter('pay_confirm3', function (flag, items) {
          if (!flag && flag !== 0) {
            return items
          }

          let items_fil = items.filter((item) => {
            if (flag === 1) {
              if (item.pay_confirm_user3.id) {
                return true
              }
            } else if (flag === 0) {
              if (!item.pay_confirm_user3.id) {
                return true
              }
            } else {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('pay_confirm2', function (flag, items) {
          if (!flag && flag !== 0) {
            return items
          }

          let items_fil = items.filter((item) => {
            if (flag === 1) {
              if (item.pay_confirm_user2.id) {
                return true
              }
            } else if (flag === 0) {
              if (!item.pay_confirm_user2.id) {
                return true
              }
            } else {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('pay_confirm1', function (flag, items) {
          if (!flag && flag !== 0) {
            return items
          }

          let items_fil = items.filter((item) => {
            if (flag === 1) {
              if (item.pay_confirm_user.id) {
                return true
              }
            } else if (flag === 0) {
              if (!item.pay_confirm_user.id) {
                return true
              }
            } else {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('pay_approve', function (flag, items) {
          if (!flag && flag !== 0) {
            return items
          }

          let items_fil = items.filter((item) => {
            if (flag === 1) {
              if (item.pay_approve_user.id) {
                return true
              }
            } else if (flag === 0) {
              if (!item.pay_approve_user.id) {
                return true
              }
            } else {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('pay_type', function (pay_type, items) {
          if (!pay_type && pay_type !== 0) {
            return items
          }
          let items_fil = items.filter((item) => {
            let pay_type_val = parseInt(item.pay_type)
            return pay_type_val == pay_type
          })
          return items_fil
        })

        cf.registerFilter('gensenzei', function (gensenzei, items) {
          if (!gensenzei && gensenzei !== 0) {
            return items
          }
          let items_fil = items.filter((item) => {
            let gensenzei_val = parseInt(item.gensenzei)
            return gensenzei_val == gensenzei
          })
          return items_fil
        })

        cf.registerFilter('updated', function (flag, items) {
          if (!flag && flag !== 0) {
            return items
          }
          let items_fil = items.filter((item) => {
            if (flag === 1) {
              if (item.update_user.id) {
                return true
              }
            } else if (flag === 0) {
              if (!item.update_user.id) {
                return true
              }
            } else {
              return true
            }
          })
          return items_fil
        })

        cf.registerFilter('exported', function (flag, items) {
          if (!flag && flag !== 0) {
            return items
          }
          let items_fil = items.filter((item) => {
            if (flag === 1) {
              if (item.export_user.id) {
                return true
              }
            } else if (flag === 0) {
              if (!item.export_user.id) {
                return true
              }
            } else {
              return true
            }
          })
          return items_fil
        })

        cf.registerFilter('paid', function (text, items) {
          if (!text || text.trim() === '') {
            return items
          }
          let items_fil = items.filter((item) => {
            if (text == '支払済') {
              if (item.paid_user.id) {
                return true
              }
            } else if (text == '未払') {
              if (!item.paid_user.id) {
                return true
              }
            } else {
              return true
            }
          }, text)

          return items_fil
        })

        return cf.runFilters()
      } catch (e) {
        console.log(e)
      }
    },
    changeDateAll() {
      try {
        if (this.selected.length == 0) {
          Snackbar.error(this.$t('message.no_invoice_selected'))
        } else {
          if (this.$refs.form_multiple_edit.validate()) {
            const auth_token = this.$store.getters.getAuthToken
            const apiUrl = this.$store.getters.apiInvoiceMultiChangeDateFunction
            const data = this.selected
            const issue_date = this.multiple_date
            this.$store
              .dispatch('multipleChangeDate', {
                data,
                apiUrl,
                issue_date,
                auth_token,
              })
              .then((response) => {
                if (response.data.status == 'success') {
                  Snackbar.success('成功')
                  for (let x = 0; x < this.selected.length; x++) {
                    for (let y = 0; y < this.tableData.length; y++) {
                      if (this.tableData[y].id == this.selected[x].id) {
                        this.tableData[y].issue_date = this.multiple_date
                      }
                    }
                  }
                  this.dialogBulkEditing = false
                }
              })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    haveApprovedUserOrPayConfirmUser() {
      const userKeys = [
        'pay_confirm_user',
        'pay_confirm_user2',
        'pay_confirm_user3',
        'pay_approve_user',
        'paid_user',
      ]
      let level = 5
      for (let i = 0; i < this.$store.getters.approveLevel; i++) {
        userKeys.push(`approve_user${level}`)
        level--
      }

      for (const data of this.selected) {
        for (const key of userKeys) {
          if (data[key] && data[key].id) {
            return true
          }
        }
      }
      return false
    },
    onClickDelete() {
      if (this.selected.length <= 0) {
        Snackbar.error('データを選択してください。')
        return
      }

      if (this.haveApprovedUserOrPayConfirmUser()) {
        this.$refs.cannotDelete.open()
        return
      }
      this.$refs.dialogDelete.open()
    },
    onClickShowAllJournals(flag) {
      this.$refs.table.changeWholeShowAll(flag)
    },
    deleteAll() {
      try {
        //const auth_token = this.$store.getters.getAuthToken
        const data = this.selected
        this.$store
          .dispatch('multipleDeleteInvoice', {
            data,
          })
          .then((response) => {
            if (response.data.status == 'success') {
              Snackbar.success(this.$t('message.selected_invoices_deleted'))
              for (let x = 0; x < this.selected.length; x++) {
                this.removeInvoice(this.selected[x].id)
              }
              this.getTableData()
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    async openExportInvoiceDialog() {
      if (this.selected.length <= 0) {
        Snackbar.error('データを選択してください。')
        return
      }
      if (this.selected.some((item) => item.export_count > 0)) {
        const yes = await this.$confirm({
          message: '出力済みのデータが含まれます。再度出力しますか？',
        })
        if (!yes) {
          return false
        }
      }
      this.dialog_export = true
    },
    exportInvoice() {
      try {
        const close = () => {
          this.export_loading = false
          this.dialog_export = false
        }
        if (this.$refs.form_export.validate()) {
          this.export_loading = true
          let auth = this.$store.getters.getAuthToken
          let url_check = this.$store.getters.apiInvoiceCheckExportFunction
          let selected_id_list = this.getSelectedID()
          const formatId = this.selected_format
          let data = {
            id_list: JSON.stringify(selected_id_list),
            export_format_id: formatId,
            encode_type: this.selected_encode_type,
            organization_id: this.$store.getters.getOrganizationID,
            user_organization_id: this.$store.getters.getUserOrganizationID,
            user_id: this.$store.getters.getUserID,
            export_header: this.export_header,
            is_quote_all: this.isQuoteAll,
            check: true,
          }
          Axios.get(url_check, {
            headers: { Authorization: auth },
            params: data,
          }).then(async (response) => {
            if (response.data.error) {
              this.export_loading = false
              const error = response.data.error
              const errorMessages = {
                no_privilege: '権限のないデータが含まれています。',
                no_record: 'エクスポートするデータがありません。',
              }
              if (errorMessages[error]) {
                Snackbar.error(errorMessages[error])
              } else {
                Snackbar.error('不明なエラー ' + error)
              }
            } else {
              const unapprovedIds = response.data.unapproved_invoice_ids
              const unapprovedInvoiceLocalIds = unapprovedIds.map((id) => {
                return this.selected.find((invoice) => invoice.id === id)
                  .image_local_id
              })
              if (unapprovedInvoiceLocalIds.length) {
                const message =
                  '仕訳の最終承認が完了していない請求書が含まれています。エクスポートを行いますか？'
                const action = await exportWarnDialogBuilder.create({
                  message,
                  unapprovedInvoiceLocalIds,
                  hideBtn: selected_id_list.length - unapprovedIds.length === 0,
                })
                if (action === 'cancel') {
                  return close()
                }
                if (action === 'approved') {
                  data.id_list = JSON.stringify(
                    selected_id_list.filter((id) => {
                      return unapprovedIds.indexOf(id) === -1
                    })
                  )
                }
              }
              const exportUrl = this.$store.getters.apiInvoiceExportFunction
              $.ajax({
                url: exportUrl,
                data: data,
                type: 'GET',
                beforeSend: function (xhr) {
                  xhr.setRequestHeader('Authorization', auth)
                },
                xhrFields: {
                  responseType: 'blob',
                },
                success: function (response) {
                  close()
                  let a = document.createElement('a')
                  let url = window.URL.createObjectURL(response)
                  a.href = url
                  a.download = this.createExportFileName(formatId)
                  document.body.appendChild(a)
                  a.click()
                  window.URL.revokeObjectURL(url)
                  const exportedIdList = JSON.parse(data.id_list)
                  createNotification(
                    `%${this.$store.getters.getUserOrganizationID}%さんが${exportedIdList.length}件の仕訳データをエクスポートしました。`,
                    3
                  )
                  Snackbar.success('データのエクスポートが完了しました。')
                  data.check = false
                  Axios.get(url_check, {
                    headers: { Authorization: auth },
                    params: data,
                  }).then((response) => {
                    let dateExport = response.data.date_export
                    let exportUser = response.data.export_user
                    this.tableDataFiltered.forEach((v) => {
                      if (exportedIdList.indexOf(v.id) != -1) {
                        v.date_export = dateExport
                        v.export_user = exportUser
                        v.export_count++
                      }
                    })
                  })
                }.bind(this),
              })
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    getInvoiceTableColumn() {
      let storageData = JSON.parse(localStorage.getItem('invtblcol'))
      let userStorage = JSON.parse(localStorage.getItem('user'))
      if (storageData) {
        let found = false
        let indexFound = null
        for (let x = 0; x < storageData.length; x++) {
          if (storageData[x].user.user_id) {
            if (storageData[x].user.user_id == userStorage.user_id) {
              found = true
              indexFound = x
            }
          }
        }
        if (found) {
          return storageData[indexFound].tbl_columns
        } else {
          return false
        }
      } else {
        return false
      }
    },
    checkLocalStorageSetting(header) {
      try {
        let index = this.storageCol.findIndex((x) => x.key == header.key)
        if (index > 0) {
          return this.storageCol[index].display
        } else {
          return true
        }
      } catch (e) {
        console.log(e)
        return true
      }
    },
    getLocalStrageSetting() {
      const start = new Date()
      try {
        // localStrage上の表示設定を取得
        this.storageCol = this.getInvoiceTableColumn()
        if (!this.storageCol) {
          // 無ければlocalStrageに保存して再取得
          this.$store.dispatch('saveInvoiceTableColumn', this.headers)
          this.storageCol = this.getInvoiceTableColumn()
        }
        consoleLog('Method', 'getLocalStrageSetting', start)
      } catch (e) {
        console.log(e)
      }
    },
    sliceText(text, i, h) {
      return sliceText(text, i, h)
    },
    onChangeJournal(event, journal) {
      try {
        onChangeJournal(event, journal)
      } catch (error) {
        console.log(error)
      }
    },
    setItemsPerPage() {
      const key = 'itemsPerPageOnInvoiceTable'
      const value = JSON.parse(localStorage.getItem(key))
      if (Number.isInteger(value)) {
        this.itemsPerPage = value
      }
    },
    onChangeItemsPerPage(itemsPerPage) {
      const key = 'itemsPerPageOnInvoiceTable'
      const value = JSON.stringify(itemsPerPage)
      localStorage.setItem(key, value)
    },
    resetPage() {
      this.$refs.table.goToPage(1)
    },
    onClickUpdateNote({ item, number, text }) {
      const data = {
        image_summary_id: item.id,
      }
      const field = `note_${number}`
      const value = text
      data[field] = value
      this.$store.dispatch('updateInvoiceNote', data).then((response) => {
        if (response.data.status === 'success') {
          Snackbar.success('更新しました')
          item[field] = value
          if (String(item.image_local_id) === this.$route.params.id) {
            const payload = {}
            payload.number = number
            payload.value = value
            this.$emit('change:invoice-note', payload)
          }
        } else {
          Snackbar.error('更新に失敗しました')
        }
      })
    },
  },
}
</script>
