function getValidMessage(isValid) {
  if (isValid == null || isValid == undefined) {
    return ''
  }
  if (typeof isValid == 'string') {
    return isValid
  }
  if (isValid) {
    return '有効'
  }
  return '無効'
}

export { getValidMessage }
