<template>
  <div>
    <invoice-status
      ref="dialogStatus"
      :status-data="statusData"
      :selected-item="selectedStatus"
      @onClick="filterBySelectedStatus()"
    />
    <v-toolbar
      flat
      color="white"
      height="50"
    >
      <v-btn
        v-tooltip="filterBtnTooltipText"
        small
        depressed
        class="mr-2"
        @click="$emit('click:open')"
      >
        <v-badge
          v-if="filterFormEntered"
          overlap
          dot
          offset-x="-50"
          offset-y="3"
          class="pa-0"
          color="red"
        >
          <span slot="badge" />

          <s-icon icon="feather-filter" color="gray-700" size="xl" />
        </v-badge>
        <s-icon v-else icon="feather-filter" color="gray-700" size="xl" />
        フィルタ
      </v-btn>

      <v-btn-toggle
        :value="toggleFilterBtn"
        mandatory
        dense
        color="blue"
        class="mr-2"
      >
        <v-btn
          v-tooltip="'読み取った日付でフィルタ'"
          small
          outlined
          @click.native="
            searchForm.filterDateType = 1
            switchDateType(1)
          "
        >
          読取
        </v-btn>

        <v-btn
          v-tooltip="'伝票日付でフィルタ'"
          small
          outlined
          @click="
            searchForm.filterDateType = 0
            switchDateType(0)
          "
        >
          伝票
        </v-btn>

        <v-btn
          v-tooltip="'支払日付でフィルタ'"
          small
          outlined
          @click.native="
            searchForm.filterDateType = 2
            switchDateType(2)
          "
        >
          支払
        </v-btn>
      </v-btn-toggle>

      <v-btn
        v-tooltip="'切替え'"
        small
        outlined
        class="grey lighten-3 mr-2"
        @click="onChangeDateSearchMode()"
      >
        <s-icon icon="feather-calendar" />
      </v-btn>

      <template v-if="dateSearchMode">
        <date-pick-input
          v-model="searchForm.headerDate"
          @change="getTableData"
        />

        <span class="mx-2">〜</span>

        <date-pick-input
          v-model="searchForm.headerDateTo"
          @change="getTableData"
        />

        <v-btn-toggle dense active-class="active-btn-opacity" class="ml-2">
          <v-btn small @click="filterBtnPrevMonth()">
            <s-icon icon="feather-chevron-left" />
          </v-btn>
          <v-btn small @click="filterBtnTargetMonth()"> 前月 </v-btn>
          <v-btn small @click="filterBtnNextMonth()">
            <s-icon icon="feather-chevron-right" />
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-else>
        <month-picker-toggle-button
          :date-from="searchForm.headerDate"
          :date-to="searchForm.headerDateTo"
          @select="setDateFromMonthPicker($event)"
        />
      </template>

      <v-spacer />

      <v-text-field
        v-tooltip="$t('message.filterBarTooltip')"
        :value="searchBar"
        append-icon="search"
        placeholder="簡単フィルタ"
        hide-details
        clearable
        class="more-dense mx-2"
        style="max-width: 400px"
        dense
        filled
        @input="onChangeBar($event)"
      />

      <v-btn
        v-tooltip="'請求書の処理状況を確認'"
        small
        depressed
        color="grey lighten-3"
        @click="openStatusDialog()"
      >
        <i class="ti-alert mr-2" />
        チェック
      </v-btn>

      <v-btn-toggle
        :value="toggleBtn"
        dense
        mandatory
        color="blue"
        class="ml-2"
      >
        <v-btn
          v-tooltip="'一覧ビューで表示'"
          small
          outlined
          active-class="bg-blue"
          :dark="toggleBtn == 0"
          @click="switchModeTable()"
        >
          <s-icon icon="feather-list" />
        </v-btn>
        <v-btn
          v-tooltip="'詳細ビューで表示'"
          small
          outlined
          active-class="bg-blue"
          :dark="toggleBtn == 1"
          @click="switchModeDetail()"
        >
          <s-icon icon="feather-sidebar" />
        </v-btn>
      </v-btn-toggle>
    </v-toolbar>
  </div>
</template>

<script>
import InvoiceStatus from './components/InvoiceStatus'
import DatePickInput from 'Components/Input/DatePickInput'
import MonthPickerToggleButton from 'Components/Button/MonthPickerToggleButton'

export default {
  components: {
    InvoiceStatus,
    DatePickInput,
    MonthPickerToggleButton,
  },
  props: {
    displayMode: {
      type: Number,
      required: true,
    },
    searchForm: {
      type: Object,
      required: true,
    },
    searchFormText: {
      type: Object,
      required: true,
    },
    statusData: {
      type: Array,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    switchModeDetail: {
      type: Function,
      required: true,
    },
    switchModeTable: {
      type: Function,
      required: true,
    },
    filterBtnTargetMonth: {
      type: Function,
      required: true,
    },
    filterBtnPrevMonth: {
      type: Function,
      required: true,
    },
    filterBtnNextMonth: {
      type: Function,
      required: true,
    },
    getTableData: {
      type: Function,
      required: true,
    },
    getStatusData: {
      type: Function,
      required: true,
    },
    clearAllFilter: {
      type: Function,
      required: true,
    },
    filterPatterns: {
      type: Array,
      required: true,
    },
    userList: {
      type: Array,
      required: true,
    },
    filterFormEntered: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
      menu_date_to: false,
      selectedStatus: {
        date: '',
        name: '',
        count: '',
      },
      dateSearchMode: true,
      searchBar: '',
    }
  },
  computed: {
    toggleBtn: function () {
      return this.displayMode === 2 ? 1 : 0
    },
    toggleFilterBtn: function () {
      const filterDateType = this.searchForm.filterDateType
      if (filterDateType == 0) {
        return 1
      } else if (filterDateType == 1) {
        return 0
      } else {
        return 2
      }
    },
    displayFilterPatterns: function () {
      let patterns = this.filterPatterns.filter((x) => x.display)
      return patterns
    },
    filterBtnTooltipText: function () {
      let text = this.appliedFilterText(this.searchForm)
      if (text == '') {
        return 'フィルタを表示／非表示'
      } else {
        return (
          'フィルタを表示／非表示<br><br>------適用中のフィルタ------<br>' +
          text
        )
      }
    },
  },
  watch: {
    'searchForm.headerDate': {
      handler: function () {
        this.switchDateType(this.searchForm.filterDateType, false)
      },
      immediate: false,
    },
    'searchForm.headerDateTo': {
      handler: function () {
        this.switchDateType(this.searchForm.filterDateType, false)
      },
      immediate: false,
    },
    'searchForm.search_bar': {
      handler: function (val) {
        this.searchBar = val
      },
      immediate: false,
    },
  },
  created() {
    this.searchBar = this.searchForm.search_bar
    this.wordQueueCount = 0
  },
  mounted() {
    this.setDateSearchMode()
  },
  methods: {
    toDateString(datetime) {
      var yyyy = datetime.getFullYear()
      var mm = ('0' + (datetime.getMonth() + 1)).slice(-2)
      var dd = ('0' + datetime.getDate()).slice(-2)
      return yyyy + '-' + mm + '-' + dd
    },
    openStatusDialog() {
      this.getStatusData()
      this.$refs.dialogStatus.open()
    },
    filterBySelectedStatus() {
      let t = this
      let date_from = t.selectedStatus.date + '-01'
      let date_split = date_from.split('-')
      let date_to = t.toDateString(new Date(date_split[0], date_split[1], 0))

      this.clearAllFilter().then(() => {
        t.$set(t.searchForm, 'filterDateType', 0)
        t.$set(t.searchForm, 'headerDate', date_from)
        t.$set(t.searchForm, 'headerDateTo', date_to)
        t.switchDateType(t.searchForm.filterDateType, false)

        const statusName = t.selectedStatus.name
        switch (statusName) {
          case 'notApproved':
            t.$set(t.searchForm, 'approve5', '未承認')
            break
          case 'approved':
            t.$set(t.searchForm, 'approve5', '承認済')
            break
          case 'notExported':
            t.$set(t.searchForm, 'exported', 0)
            break
          case 'exported':
            t.$set(t.searchForm, 'exported', 1)
            break
          case 'notUpdated':
            t.$set(t.searchForm, 'updated', 0)
            break
          case 'updated':
            t.$set(t.searchForm, 'updated', 1)
            break
          case 'notPayApproved':
            t.$set(t.searchForm, 'pay_approve', 0)
            break
          case 'payApproved':
            t.$set(t.searchForm, 'pay_approve', 1)
            break
          case 'notPayConfirmed1':
            t.$set(t.searchForm, 'pay_confirm1', 0)
            break
          case 'payConfirmed1':
            t.$set(t.searchForm, 'pay_confirm1', 1)
            break
          case 'notPayConfirmed2':
            t.$set(t.searchForm, 'pay_confirm2', 0)
            break
          case 'payConfirmed2':
            t.$set(t.searchForm, 'pay_confirm2', 1)
            break
          case 'notPayConfirmed3':
            t.$set(t.searchForm, 'pay_confirm3', 0)
            break
          case 'payConfirmed3':
            t.$set(t.searchForm, 'pay_confirm3', 1)
            break
        }
        this.getTableData()
        this.$refs.dialogStatus.close()
      })
    },
    switchDateType(type, update = true) {
      this.$set(this.searchForm, 'date', null)
      this.$set(this.searchForm, 'date_to', null)
      this.$set(this.searchForm, 'reading_date_from', null)
      this.$set(this.searchForm, 'reading_date_to', null)
      this.$set(this.searchForm, 'pay_date_from', null)
      this.$set(this.searchForm, 'pay_date_to', null)
      switch (type) {
        case 0:
          this.$set(this.searchForm, 'date', this.searchForm.headerDate)
          this.$set(this.searchForm, 'date_to', this.searchForm.headerDateTo)
          this.$set(this.searchForm.settingsFilterBlank, 'issueDate', false)
          break
        case 1:
          this.$set(
            this.searchForm,
            'reading_date_from',
            this.searchForm.headerDate
          )
          this.$set(
            this.searchForm,
            'reading_date_to',
            this.searchForm.headerDateTo
          )
          break
        case 2:
          this.$set(
            this.searchForm,
            'pay_date_from',
            this.searchForm.headerDate
          )
          this.$set(
            this.searchForm,
            'pay_date_to',
            this.searchForm.headerDateTo
          )
          break
      }

      if (update) {
        this.getTableData()
      }
    },
    onChangeBar(val) {
      try {
        this.searchBar = val
        this.wordQueueCount++
        setTimeout(() => {
          this.wordQueueCount--
          if (this.wordQueueCount === 0) {
            this.$emit('input:searchBar', val)
          }
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },
    setDateFromMonthPicker(event) {
      try {
        this.$set(this.searchForm, 'headerDate', event.dateFrom)
        this.$set(this.searchForm, 'headerDateTo', event.dateTo)
        this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    setDateSearchMode() {
      const key = 'dateSearchModeOnInvoiceHeader'
      if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
        const value = JSON.parse(localStorage.getItem(key))
        this.dateSearchMode = value
      }
    },
    onChangeDateSearchMode() {
      const mode = !this.dateSearchMode
      this.dateSearchMode = mode
      const key = 'dateSearchModeOnInvoiceHeader'
      const value = JSON.stringify(mode)
      localStorage.setItem(key, value)
    },
    appliedFilterText(filter) {
      try {
        if (this.userList.length <= 0) {
          return
        }
        let ignoreKeys = [
          'headerDate',
          'headerDateTo',
          'filterDateType',
          'date',
          'date_to',
          'reading_date_from',
          'reading_date_to',
          'pay_date_from',
          'pay_date_to',
          'id_1',
          'id_2',
          'amount_1',
          'amount_2',
        ]
        let user_keys = [
          'upload_user',
          'update_user',
          'approve_user',
          'export_user',
          'pay_export_user',
          'pay_approve_user',
          'paid_user',
        ]
        let journal_keys = [
          'department',
          'debit_account_title',
          'debit_sub_account',
          'debit_department',
          'debit_vendor',
          'debit_project',
          'debit_segment',
          'debit_item',
          'debit_tag',
          'debit_walletable',
          'debit_tax_class',
          'credit_account_title',
          'credit_sub_account',
          'credit_department',
          'credit_vendor',
          'credit_project',
          'credit_segment',
          'credit_item',
          'credit_tag',
          'credit_walletable',
          'credit_tax_class',
        ]
        let result = ''
        const searchFormText = this.searchFormText

        if (filter['id_1'] || filter['id_2']) {
          result +=
            searchFormText['id_1'].label +
            ' = ' +
            filter['id_1'] +
            ' ~ ' +
            filter['id_2'] +
            '<br>'
        }
        if (filter['amount_1'] || filter['amount_2']) {
          result +=
            searchFormText['amount_1'].label +
            ' = ' +
            filter['amount_1'] +
            ' ~ ' +
            filter['amount_2'] +
            '<br>'
        }
        if (filter.deletedStatusChoice == 1) {
          ignoreKeys.push('deletedStatusChoice')
        }
        if (
          filter.settingsFilterBlank &&
          filter.settingsFilterBlank.issueDate
        ) {
          result += '伝票日付 = 未入力を検索<br>'
        }

        for (let key of Object.keys(filter)) {
          if (
            ignoreKeys.includes(key) === false &&
            !(
              filter[key] === '' ||
              filter[key] === null ||
              filter[key] === undefined
            ) &&
            !(searchFormText[key] === null || searchFormText[key] === undefined)
          ) {
            let textObj = searchFormText[key]
            let selectedText = ''
            let index = null
            if (textObj.label) {
              if (journal_keys.includes(key)) {
                selectedText = filter[key].name
              } else if (user_keys.includes(key)) {
                //ユーザ名取得
                if (filter[key] == 0) {
                  selectedText = '自分'
                } else {
                  index = this.userList.findIndex((x) => x.id == filter[key])
                  if (index !== -1 && this.userList[index].user) {
                    selectedText = this.userList[index].user.full_name
                  }
                }
              } else {
                if (textObj.items) {
                  //選択した値がitemsを持っていた場合、名前を取得する
                  index = textObj.items.findIndex((x) => x.id == filter[key])
                  if (index >= 0 && textObj.items[index].name) {
                    selectedText = textObj.items[index].name
                  } else if (
                    index >= 0 &&
                    textObj.items[index].user.full_name
                  ) {
                    selectedText = textObj.items[index].user.full_name
                  }
                } else {
                  //いずれにも該当しない場合は選択された値をそのまま取得
                  selectedText = filter[key]
                }
              }
              if (!(!selectedText || selectedText === undefined)) {
                result += textObj.label + ' = ' + selectedText + '<br>'
              }
            }
          }
        }
        if (result) {
          return result
        } else {
          return ''
        }
      } catch (e) {
        console.log(e)
        return ''
      }
    },
  },
}
</script>
